import Api from './Api';

const URL = 'customers';

const search = async searchData =>
  Api.get(`${URL}?query=${searchData}`).then(
    response => response.data['hydra:member'],
  );

const post = async data => Api.post(URL, data).then(response => response.data);

const put = async (id, data) =>
  Api.put(`${URL}/${id}`, data).then(response => response.data);

export default {
  search,
  post,
  put,
};
