import React from 'react';

const Textarea = ({
  name,
  label,
  value,
  onChange,
  placeholder = '',
  error = '',
  className = '',
}) => (
  <div className={`form-textarea ${className}`}>
    {label && <label htmlFor={name}>{label}</label>}
    <textarea
      value={value}
      onChange={onChange}
      placeholder={placeholder || label}
      name={name}
      id={name}
      className={error && 'is-invalid'}
    />
    {error && <span className='form-textarea__error'>{error}</span>}
  </div>
);

export default Textarea;
