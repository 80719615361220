import Api from './Api';

const URL = 'quotes';

const formatApi2App = quoteApi => {
  const quoteApp = {
    quoteIri: quoteApi['@id'],
    id: quoteApi.id,
    customer: {
      '@id': quoteApi.customer ? quoteApi.customer['@id'] : null,
      id: quoteApi.customer ? quoteApi.customer.id : null,
      firstname: quoteApi.customer
        ? quoteApi.customer.firstname
        : quoteApi.leadFirstname,
      lastname: quoteApi.customer
        ? quoteApi.customer.lastname
        : quoteApi.leadLastname,
      businessName: quoteApi.customer ? quoteApi.customer.businessName : '',
      email: quoteApi.customer ? quoteApi.customer.email : quoteApi.leadEmail,
      phone: quoteApi.customer ? quoteApi.customer.phone : quoteApi.leadPhone,
      vip: quoteApi.customer ? quoteApi.customer.vip : false,
      street: quoteApi.customer ? quoteApi.customer.street : '',
      zipCode: quoteApi.customer ? quoteApi.customer.zipCode : '',
      city: quoteApi.customer ? quoteApi.customer.city : '',
      country: quoteApi.customer ? quoteApi.customer.country : '',
    },
    vehicle: {
      '@id': quoteApi.vehicle ? quoteApi.vehicle['@id'] : null,
      id: quoteApi.vehicle ? quoteApi.vehicle.id : null,
      plate: quoteApi.vehicle
        ? quoteApi.vehicle.plate
        : quoteApi.leadVehiclePlate,
      category: quoteApi.vehicle
        ? quoteApi.vehicle.category
        : quoteApi.leadVehicleCategory,
      manufacturerName: quoteApi.vehicle
        ? quoteApi.vehicle.manufacturerName
        : quoteApi.leadVehicleManufacturerName,
      modelName: quoteApi.vehicle
        ? quoteApi.vehicle.modelName
        : quoteApi.leadVehicleModelName,
      model: quoteApi.vehicle ? quoteApi.vehicle.model : null,
    },
    items: quoteApi.items,
    calculatedTotalPrice: quoteApi.calculatedTotalPrice,
    repairOrderVehicleImageToShow: quoteApi.repairOrderVehicleImageToShow,
    state: '',
    signatureImage: quoteApi.signatureImage,
    reference: quoteApi.reference,
    repairOrders: quoteApi.repairOrders,
  };

  if (quoteApi.originSimulation && !quoteApi.converted) {
    quoteApp.state = 'SIMULATION';
  } else if (!quoteApi.accepted) {
    quoteApp.state = 'DEVIS EN COURS';
  } else if (quoteApi.accepted) {
    quoteApp.state = 'DEVIS ACCEPTÉ';
  }

  return quoteApp;
};

const search = async searchData =>
  Api.get(`${URL}?query=${searchData}`).then(response => {
    const quotesFormatted = [];
    const quotes = response.data['hydra:member'];
    quotes.forEach(quote => {
      quotesFormatted.push(formatApi2App(quote));
    });

    return quotesFormatted;
  });

const findOne = async id =>
  Api.get(`${URL}/${id}`).then(response => formatApi2App(response.data));

const post = async data =>
  Api.post(URL, data).then(response => formatApi2App(response.data));

const put = async (id, data) =>
  Api.put(`${URL}/${id}`, data).then(response => formatApi2App(response.data));

const attachSignature = async (id, file) => {
  const formData = new FormData();
  formData.append('file', file, 'signature.png');
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

  return Api.post(`${URL}/${id}/upload/signature`, formData, config).then(
    response => formatApi2App(response.data),
  );
};

export default {
  search,
  findOne,
  post,
  put,
  attachSignature,
};
