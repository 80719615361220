/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import Input from 'components/Form/Input';
import QuotesApi from 'services/api/QuotesApi';
import Face3D from 'assets/images/3d-face.png';
import SearchIcon from 'assets/images/icons/search.svg';

const QuoteStep = props => {
  const {
    repairOrder,
    setRepairOrder,
    setSteps,
    steps,
    showQuote,
    showRepairOrder,
  } = props;

  const [search, setSearch] = useState({
    text: '',
    result: null,
  });

  const nextStep = () => {
    setSteps({
      index: steps.index + 1,
      list: {
        ...steps.list,
        [steps.index]: {
          ...steps.list[steps.index],
          isValid: true,
        },
        [steps.index + 1]: {
          ...steps.list[steps.index + 1],
          isValid: true,
        },
      },
    });
  };

  const handleSearchChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;

    if (/^(\d{0,})$/.test(value) || value === '') {
      setSearch({ ...search, [name]: value });
    }
  };

  const handleSearch = async () => {
    if (search.text === '') {
      return;
    }

    try {
      const quotesResult = await QuotesApi.findOne(search.text);
      if (quotesResult.state === 'SIMULATION') {
        if (
          confirm(
            `Le numéro ${quotesResult.id} correspond à une simuation. Voulez-vous voir la simulation ?`,
          )
        ) {
          showQuote(quotesResult);
        }
      } else if (quotesResult.state === 'DEVIS EN COURS') {
        if (
          confirm(
            `Le devis ${quotesResult.id} doit d'abord être accepté. Voulez-vous voir le devis ?`,
          )
        ) {
          showQuote(quotesResult);
        }
      }

      if (Object.values(quotesResult.repairOrders).length > 0) {
        if (
          confirm(
            `L'OR a déjà été créé pour le devis ${quotesResult.id}. Voulez-vous voir l'OR' ?`,
          )
        ) {
          showRepairOrder(quotesResult.repairOrders);
        }
      } else {
        setSearch({
          ...search,
          result: {
            id: quotesResult.id,
            customer: {
              '@id': quotesResult.customer['@id'],
              id: quotesResult.customer.id,
              firstname: quotesResult.customer.firstname,
              lastname: quotesResult.customer.lastname,
              businessName: quotesResult.customer.businessName,
              email: quotesResult.customer.email,
              phone: quotesResult.customer.phone,
            },
            vehicle: {
              '@id': quotesResult.vehicle['@id'],
              id: quotesResult.vehicle.id,
              plate: quotesResult.vehicle.plate,
              category: quotesResult.vehicle.category,
              manufacturerName: quotesResult.vehicle.manufacturerName,
              modelName: quotesResult.vehicle.modelName,
            },
            items: quotesResult.items,
            calculatedTotalPrice: '',
            repairOrderVehicleImageToShow:
              quotesResult.repairOrderVehicleImageToShow,
            quoteIri: quotesResult.quoteIri,
            state: quotesResult.state,
            repairOrders: quotesResult.repairOrders,
          },
        });
      }
    } catch (error) {
      setSearch({ ...search, result: null });
      alert('Aucun résultat');
    }
  };

  const handleQuoteSelected = quoteSelected => {
    setRepairOrder({
      ...repairOrder,
      quoteIri: quoteSelected.quoteIri,
      customer: quoteSelected.customer,
      vehicle: quoteSelected.vehicle,
      repairsRequested: quoteSelected.items,
      calculatedTotalPrice: quoteSelected.calculatedTotalPrice,
      repairOrderVehicleImageToShow:
        quoteSelected.repairOrderVehicleImageToShow,
    });
    nextStep();
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  useEffect(() => {
    if (search.result === null) {
      return;
    }

    let amount = 0;
    search.result.items.map(item => (amount += item.quantity * item.unitPrice));
    setSearch({
      ...search,
      result: { ...search.result, calculatedTotalPrice: amount },
    });
  }, [search.result && search.result.items]);

  return (
    <div className='customer-step'>
      <img src={Face3D} alt='Visage 3D' />
      <div className='step step--search'>
        <div className='step__position'>
          étape {steps.index + 1}/{Object.values({ ...steps.list }).length}
        </div>

        <div className='step__title'>
          <span>Trouver un devis</span>{' '}
          <img src={SearchIcon} alt='Icone loupe' />
        </div>
        <p className='step__paragraph'>Renseignez le numéro de devis</p>
        <Input
          name='text'
          type='number'
          value={search.text}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
        />
        <div className='step__buttons'>
          <button
            type='button'
            className='button button-primary'
            onClick={handleSearch}
            disabled={search.text === ''}
          >
            Chercher
          </button>
        </div>

        {search.result !== null && search.result.state === 'DEVIS ACCEPTÉ' && (
          <div
            className='search-card'
            onClick={() => handleQuoteSelected(search.result)}
          >
            <div>{`Devis n°: ${search.result.id}`}</div>
            <div>{`${search.result.calculatedTotalPrice} €`}</div>
            <div>
              <div>{`${search.result.customer.firstname} ${search.result.customer.lastname}`}</div>
              <div>{search.result.customer.businessName}</div>
              <div>{search.result.customer.email}</div>
              <div>{search.result.customer.phone}</div>
            </div>
            <div>
              <div>{search.result.repairOrderVehicleImageToShow}</div>
              <div>{`${search.result.vehicle.manufacturerName} - ${search.result.vehicle.modelName}`}</div>
              <div>{search.result.vehicle.plate}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuoteStep;
