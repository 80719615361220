import React from 'react';

const Progressbar = props => {
  const { steps, setSteps } = props;

  return (
    <div className='progressbar'>
      <div className='progressbar__steps'>
        {Object.values({ ...steps.list }).map((step, index) => (
          <button
            key={step.name}
            type='button'
            className={`progressbar__step ${
              steps.index === index && ' progressbar__step--is-active'
            }`}
            onClick={() => {
              if (step.isValid) setSteps({ ...steps, index });
            }}
          >
            <span>{step.name}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Progressbar;
