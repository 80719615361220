/* eslint-disable no-alert */
import React, { useState } from 'react';
import Input from 'components/Form/Input';
import QuotesApi from 'services/api/QuotesApi';
import SearchIcon from 'assets/images/icons/search.svg';

const SearchStep = props => {
  const { quote, setQuote, steps, setSteps } = props;

  const [search, setSearch] = useState({
    text: '',
    result: [],
  });

  const nextStep = () => {
    setSteps({
      index: steps.index + 1,
      list: {
        ...steps.list,
        [steps.index]: {
          ...steps.list[steps.index],
          isValid: true,
        },
        [steps.index + 1]: {
          ...steps.list[steps.index + 1],
          isValid: true,
        },
      },
    });
  };

  const handleSearchChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;

    setSearch({ ...search, [name]: value });
  };

  const handleSearch = async () => {
    if (search.text === '') {
      return;
    }

    try {
      const quotesResult = await QuotesApi.search(search.text);

      setSearch({
        ...search,
        result: quotesResult,
      });

      if (Object.values(quotesResult).length === 0) {
        alert('Aucun résultat');
      }
    } catch (error) {
      setSearch({ ...search, result: null });
      alert('Aucun résultat');
    }
  };

  const handleQuoteSelected = quoteSelected => {
    nextStep();
    setQuote(quoteSelected);
  };

  const handleCreateQuote = () => {
    nextStep();
    setQuote({
      ...quote,
      state: 'DEVIS EN COURS',
    });
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className='search-step'>
      <div className='step step--search'>
        <div className='step__position'>
          étape {steps.index + 1}/{steps.list.length}
        </div>

        <div className='step__title'>
          <span>Trouver un devis ou une simulation</span>{' '}
          <img src={SearchIcon} alt='Icone loupe' />
          <button
            type='button'
            className='button button-primary'
            onClick={handleCreateQuote}
          >
            Créer un devis
          </button>
        </div>
        <p className='step__paragraph'>
          Prénom, Nom, Numéro de simulation, Numéro de devis
        </p>
        <div className='search-step__form'>
          <Input
            name='text'
            value={search.text}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
          />
          <button
            type='button'
            className='button button-primary'
            onClick={handleSearch}
            disabled={search.text === ''}
          >
            Chercher
          </button>
        </div>
        <div className='search-step__results'>
          {search.result !== [] &&
            search.result.map(quoteResult => (
              <div
                key={quoteResult.id}
                className={`card card--search${
                  quoteResult.state === 'DEVIS EN COURS'
                    ? ' card--in-progress'
                    : ''
                }${
                  quoteResult.state === 'DEVIS ACCEPTÉ' ? ' card--accepted' : ''
                }`}
                onClick={() => handleQuoteSelected(quoteResult)}
              >
                <div>{quoteResult.reference}</div>
                <div>{`${quoteResult.calculatedTotalPrice} €`}</div>
                <div>
                  <div>{`${quoteResult.customer.firstname} ${quoteResult.customer.lastname}`}</div>
                  <div>{quoteResult.customer.businessName}</div>
                  <div>{quoteResult.customer.email}</div>
                  <div>{quoteResult.customer.phone}</div>
                </div>
                <div>
                  <div>{quoteResult.repairOrderVehicleImageToShow}</div>
                  <div>{`${quoteResult.vehicle.manufacturerName} - ${quoteResult.vehicle.modelName}`}</div>
                  <div>{quoteResult.vehicle.plate}</div>
                </div>
                <div>{quoteResult.state}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SearchStep;
