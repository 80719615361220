import Api from './Api';

const URL = 'repair_orders';

const search = async searchData =>
  Api.get(`${URL}?query=${searchData}`).then(response => {
    return {
      items: response.data['hydra:member'],
      totalItems: response.data['hydra:totalItems'],
    };
  });

const findAll = async (
  page,
  repairOrderArchived,
  orderByCreatedAt,
  orderByEstimatedAt,
) => {
  let params = {
    page,
    'quote.archived': repairOrderArchived,
  };

  if (orderByCreatedAt !== '') {
    params = {
      ...params,
      'order[createdAt]': orderByCreatedAt,
    };
  } else if (orderByEstimatedAt !== '') {
    params = {
      ...params,
      'order[estimatedDate]': orderByEstimatedAt,
    };
  }

  return Api.get(URL, {
    params,
  }).then(response => {
    return {
      items: response.data['hydra:member'],
      totalItems: response.data['hydra:totalItems'],
    };
  });
};

const create = async data =>
  Api.post(URL, data).then(response => response.data.id);

const put = async (id, data) =>
  Api.put(`${URL}/${id}`, data).then(response => response.data);

const attachSignature = async (id, file) => {
  const formData = new FormData();
  formData.append('file', file, 'signature.png');
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

  Api.post(`${URL}/${id}/upload/signature`, formData, config).then(
    response => response.data,
  );
};

export default {
  search,
  findAll,
  create,
  put,
  attachSignature,
};
