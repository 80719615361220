import React from 'react';
import Input from 'components/Form/Input';
import Datepicker from 'components/Form/Datepicker';
import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');

const RepairStep = props => {
  const { repairOrder, setRepairOrder, setSteps, steps } = props;

  const nextStep = () => {
    setSteps({
      index: steps.index + 1,
      list: {
        ...steps.list,
        [steps.index]: {
          ...steps.list[steps.index],
          isValid: true,
        },
        [steps.index + 1]: {
          ...steps.list[steps.index + 1],
          isValid: true,
        },
      },
    });
  };

  const previousStep = () => {
    setSteps({
      ...steps,
      index: steps.index - 1,
    });
  };

  const handlePartsOrderChange = partsOrderBool => {
    setRepairOrder({ ...repairOrder, partsToOrder: partsOrderBool });
  };

  const handleAmountChange = ({ currentTarget }) => {
    const { value } = currentTarget;

    if (/^([+-]?\d*\.?\d*)$/.test(value) || value === '') {
      setRepairOrder({ ...repairOrder, calculatedTotalPrice: value });
    }
  };

  const handlePrevious = () => {
    setRepairOrder(repairOrder);
    previousStep();
  };

  const handleNext = () => {
    setRepairOrder(repairOrder);
    nextStep();
  };

  return (
    <div className='repair-step'>
      <div className='repair-step__position'>
        étape {steps.index + 1}/{Object.values({ ...steps.list }).length}
      </div>

      <div className='repair-step__reparation-type'>
        <div className='repair-step__title'>Réparations demandées</div>
        <div>
          {repairOrder.repairsRequested.map(repairRequested => (
            <button
              key={repairRequested.id}
              type='button'
              className='button button-primary'
            >
              {repairRequested.name}
            </button>
          ))}
        </div>
      </div>

      <div className='repair-step__parts-order'>
        <div className='repair-step__title'>Commande de pièces ?</div>
        <div>
          <button
            type='button'
            onClick={() => handlePartsOrderChange(true)}
            className={`button button-primary ${
              !repairOrder.partsToOrder && 'button-primary--not-selected'
            }`}
          >
            oui
          </button>
          <button
            type='button'
            onClick={() => handlePartsOrderChange(false)}
            className={`button button-primary ${
              repairOrder.partsToOrder && 'button-primary--not-selected'
            }`}
          >
            non
          </button>
        </div>
      </div>

      <div className='repair-step__amount'>
        <div className='repair-step__title'>Montant des réparations</div>
        <div>
          <Input
            name='calculatedTotalPrice'
            type='number'
            patter='^([+-]?\d*\.?\d*)$'
            className='repair-step__amount-input'
            value={repairOrder.calculatedTotalPrice}
            onChange={handleAmountChange}
            disabled
          />
        </div>
      </div>

      <div className='repair-step__date'>
        <div className='repair-step__title'>
          Date de fin de travaux estimée ?
        </div>
        <div>
          <div className='repair-step__date-show'>
            {moment(repairOrder.estimatedDate).format('dddd DD MMMM YYYY')}
          </div>
          <Datepicker
            onChange={newDate =>
              setRepairOrder({ ...repairOrder, estimatedDate: newDate })
            }
          />
        </div>
      </div>

      <div className='repair-step__buttons'>
        <button
          type='button'
          className='button button-secondary'
          onClick={handlePrevious}
        >
          Précédent
        </button>
        <button
          type='button'
          className='button button-primary'
          onClick={handleNext}
        >
          Suivant
        </button>
      </div>
    </div>
  );
};

export default RepairStep;
