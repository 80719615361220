import React from 'react';

const Input = ({
  name,
  label,
  value,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  placeholder = '',
  type = 'text',
  pattern = '',
  error = '',
  className = '',
  disabled = false,
  list = '',
  required = false,
}) => (
  <div className={`form-input ${className}`}>
    {label && (
      <label htmlFor={name}>
        {label}
        {required && ' *'}
      </label>
    )}
    <input
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      type={type}
      pattern={pattern}
      placeholder={placeholder || label}
      name={name}
      id={name}
      className={error && 'is-invalid'}
      disabled={disabled}
      list={list}
    />
    {error && <span className='form-input__error'>{error}</span>}
  </div>
);

export default Input;
