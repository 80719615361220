import React from 'react';

const Select = ({
  name,
  label,
  value,
  onChange,
  placeholder = '',
  type = 'text',
  error = '',
  className = '',
  options = [],
  required = false,
}) => (
  <div className={`form-select ${className}`}>
    {label && (
      <label htmlFor={name}>
        {label}
        {required && ' *'}
      </label>
    )}
    <select
      value={value}
      onChange={onChange}
      type={type}
      placeholder={placeholder || label}
      name={name}
      id={name}
      className={error && 'is-invalid'}
    >
      {Object.values({ ...options }).map(option => (
        <option key={option.label} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
    {error && <span className='form-select__error'>{error}</span>}
  </div>
);

export default Select;
