/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SignaturePad from 'react-signature-pad-wrapper';
import Modal from 'components/Modal/Modal';
import CustomerManager from 'components/Quote/Customer/Manage';
import RepairManager from 'components/Quote/Repair/RepairCollection';
import VehicleManager from 'components/Quote/Vehicle/Manage';
import Validation from 'utils/validation';
import CategoriesApi from 'services/api/CategoriesApi';
import CustomersApi from 'services/api/CustomersApi';
import ModelsApi from 'services/api/ModelsApi';
import VehiclesApi from 'services/api/VehiclesApi';
import QuotesApi from 'services/api/QuotesApi';
import SignatureConvert from 'utils/signatureConvert';
import moment from 'moment';
import 'moment/locale/fr';
import NoVip from 'assets/images/icons/noVip.svg';
import Vip from 'assets/images/icons/vip.svg';
import Trash from 'assets/images/icons/trash.svg';

const SimulationStep = props => {
  const {
    quote,
    setQuote,
    signatureRef,
    convertQuoteAcceptedToRepairOrder,
    showRepairOrder,
  } = props;
  const [showCustomerManager, setShowCustomerManager] = useState(false);
  const [category, setCategory] = useState('');
  const [showRepairManager, setShowRepairManager] = useState(false);
  const [showVehicleManager, setShowVehicleManager] = useState(false);
  const [showCgu, setShowCgu] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [showResource, setShowResource] = useState(false);
  const [isValidCustomer, setIsValidCustomer] = useState(true);
  const [isValidRepair, setIsValidRepair] = useState(true);
  const [isValidVehicle, setIsValidVehicle] = useState(true);
  const [isValidSignature, setIsValidSignature] = useState(true);
  const [isValidCgu, setIsValidCgu] = useState(false);
  const [customerIsUpdated, setCustomerIsUpdated] = useState(false);
  const [repairIsUpdated, setRepairIsUpdated] = useState(false);
  const [vehicleIsUpdated, setVehicleIsUpdated] = useState(false);
  const [isMailSent, setIsMailSent] = useState(false);
  const [resources, setResources] = useState([]);
  const [resourceToShow, setResourceToShow] = useState(null);

  const validateField = (value, rules) => {
    let isValid = true;

    if (rules !== []) {
      rules.forEach(rule => {
        if (isValid) {
          if (rule === 'required' && value === '') {
            isValid = false;
          }

          if (rule === 'email' && !Validation.isEmail(value)) {
            isValid = false;
          }
        }
      });
    }

    return isValid;
  };

  const fetchCategorie = async () => {
    if (
      quote.vehicle.category !== null &&
      quote.vehicle.category !== undefined
    ) {
      const categoryFromApi = await CategoriesApi.findOne(
        quote.vehicle.category.split('').pop(),
      );
      setCategory(categoryFromApi.name);
    }
  };

  const fetchResources = async () => {
    await axios
      .get(`${process.env.REACT_APP_RESOURCES_QUOTE_URL}:${quote.id}`)
      .then(response => {
        if (response.status === 200) {
          setResources(response.data);
        }
      });
  };

  const updateCustomer = customer => {
    setQuote({
      ...quote,
      customer,
    });
    setShowCustomerManager(false);
    setCustomerIsUpdated(true);
  };

  const updateRepairs = (repairs, total) => {
    const repairsFormatted = [];
    Object.values(repairs).forEach(repair => {
      repairsFormatted.push({
        taskPrice: repair['@id'],
        quantity: repair.quantity,
        unitPrice: repair.unitPrice,
        taxPercentage: repair.taxPercentage,
        description: repair.description,
        name: repair.name,
        editable: repair.editable,
        removable: repair.removable,
      });
    });

    setQuote({
      ...quote,
      calculatedTotalPrice: parseFloat(total),
      items: repairsFormatted,
    });
    setShowRepairManager(false);
    setRepairIsUpdated(true);
  };

  const updateVehicle = vehicle => {
    setQuote({
      ...quote,
      vehicle,
    });
    setShowVehicleManager(false);
    setVehicleIsUpdated(true);
  };

  const convertTtc2Ht = (priceTtc, taxPercentage) => {
    return parseFloat(priceTtc) / (1 + parseFloat(taxPercentage) / 100);
  };

  const handleCustomerVip = () => {
    setQuote({
      ...quote,
      customer: {
        ...quote.customer,
        vip: !quote.customer.vip,
      },
    });
    setCustomerIsUpdated(true);
  };

  const handleConvertSimulationQuoteToInProgressQuote = async () => {
    if (quote.state !== 'SIMULATION') {
      return;
    }

    const vehicleModel = await ModelsApi.search(quote.vehicle.modelName, true);
    if (vehicleModel === null || vehicleModel === undefined) {
      return;
    }

    const customerToSend = {
      firstname: quote.customer.firstname,
      lastname: quote.customer.lastname,
      businessName: quote.customer.businessName,
      email: quote.customer.email,
      phone: quote.customer.phone,
      vip: quote.customer.vip,
      street: quote.customer.street,
      zipCode: quote.customer.zipCode,
      city: quote.customer.city,
      country: quote.customer.country,
    };

    const vehicleToSend = {
      plate: quote.vehicle.plate,
      category: quote.vehicle.category,
      model: vehicleModel['@id'],
    };

    const customerFromApi = await CustomersApi.search(quote.vehicle.plate);
    const vehicleFromApi = await VehiclesApi.search(quote.vehicle.plate);

    const quoteUpdated = await QuotesApi.put(quote.id, {
      customer:
        Object.values(customerFromApi).length === 0
          ? customerToSend
          : customerFromApi[0]['@id'],
      vehicle:
        Object.values(vehicleFromApi).length === 0
          ? vehicleToSend
          : vehicleFromApi[0]['@id'],
    });

    setQuote(quoteUpdated);
  };

  const handleSaveQuote = async () => {
    let quoteToUpdate = { ...quote };

    if (customerIsUpdated) {
      const customer = {
        id: quote.customer.id,
        firstname: quote.customer.firstname,
        lastname: quote.customer.lastname,
        businessName: quote.customer.businessName,
        email: quote.customer.email,
        phone: quote.customer.phone,
        street: quote.customer.street,
        zipCode: quote.customer.zipCode,
        city: quote.customer.city,
        country: quote.customer.country,
        vip: quote.customer.vip,
        note: '',
      };
      let customerUpdated = null;

      if (customer.id === null || customer.id === undefined) {
        customerUpdated = await CustomersApi.post(customer);
      } else {
        customerUpdated = await CustomersApi.put(quote.customer.id, customer);
      }

      quoteToUpdate = {
        ...quoteToUpdate,
        customer: customerUpdated,
      };
      // setQuote({
      //   ...quote,
      //   customer: customerUpdated,
      // });
    }

    if (vehicleIsUpdated) {
      const vehicle = {
        id: quote.vehicle.id,
        plate: quote.vehicle.plate,
        category: quote.vehicle.category,
        manufacturerName: quote.vehicle.manufacturerName,
        modelName: quote.vehicle.modelName,
        model: quote.vehicle.model,
      };
      let vehicleUpdated = null;

      if (vehicle.id === null || vehicle.id === undefined) {
        vehicleUpdated = await VehiclesApi.post(vehicle);
      } else {
        vehicleUpdated = await VehiclesApi.put(quote.vehicle.id, vehicle);
      }

      quoteToUpdate = {
        ...quoteToUpdate,
        vehicle: vehicleUpdated,
      };
      // setQuote({
      //   ...quote,
      //   vehicle: vehicleUpdated,
      // });
    }

    if (repairIsUpdated) {
      if (quote.id) {
        QuotesApi.put(quote.id, {
          items: quote.items,
        });
      }
    }

    setQuote(quoteToUpdate);

    if ((customerIsUpdated || repairIsUpdated) && quote.id !== null) {
      const quoteFromApi = await QuotesApi.findOne(quote.id);
      setQuote(quoteFromApi);
    }

    setCustomerIsUpdated(false);
    setVehicleIsUpdated(false);
    setRepairIsUpdated(false);
  };

  const handleConvertInProgressQuoteToAcceptedQuote = async () => {
    try {
      let quoteFromApi = null;
      if (quote.id === null || quote.id === undefined) {
        quoteFromApi = await QuotesApi.post({
          customer: quote.customer['@id'],
          vehicle: quote.vehicle['@id'],
          items: quote.items,
          lifecycleState: 'accepted',
        });
      } else {
        quoteFromApi = await QuotesApi.put(quote.id, {
          lifecycleState: 'accepted',
        });
      }

      const signatureDataUrlSplit = signatureRef.current.toDataURL().split(',');
      const quoteWithSignatureFromApi = await QuotesApi.attachSignature(
        quoteFromApi.id,
        SignatureConvert.b64ToBlob(signatureDataUrlSplit[1], 'image/png'),
      );

      setQuote({
        ...quoteWithSignatureFromApi,
        state: 'DEVIS ACCEPTÉ',
      });
      alert('Le devis a bien été accepté');
    } catch (error) {
      alert('Une erreur est survenue');
    }
  };

  const handleCreateInProgressQuote = async () => {
    try {
      const quoteFromApi = await QuotesApi.post({
        customer: quote.customer['@id'],
        vehicle: quote.vehicle['@id'],
        items: quote.items,
        origin: 'garage',
      });

      setQuote({
        ...quoteFromApi,
        state: 'DEVIS EN COURS',
      });
      alert('Le devis a bien été créé');
    } catch (error) {
      alert('Une erreur est survenue');
    }
  };

  const handleSendEmail = async () => {
    setIsMailSent(true);
    let emailState = '';

    if (quote.state === 'DEVIS ACCEPTÉ') {
      emailState = '/accepted';
    } else if (quote.state === 'DEVIS EN COURS') {
      emailState = '/resend';
    }

    const responseStatus = await axios
      .get(
        `${process.env.REACT_APP_DOMAIN_URL}/quote/send/${quote.id}${emailState}`,
      )
      .then(response => response.status);
    if (responseStatus !== 200) {
      setIsMailSent(false);
    }
  };

  useEffect(() => {
    if (signatureRef.current === undefined) {
      return;
    }

    const signatureInstance = signatureRef.current.instance;
    setIsValidSignature(!signatureInstance.isEmpty());

    signatureRef.current.instance.addEventListener('endStroke', () =>
      setIsValidSignature(!signatureInstance.isEmpty()),
    );
  }, [signatureRef]);

  useEffect(() => {
    fetchCategorie();
  }, [quote.vehicle]);

  useEffect(() => {
    fetchResources();
  }, []);

  return (
    <div className='quote-simulation-step'>
      <div className='quote-simulation-step__title'>
        {quote.id !== null ? quote.reference : "Création d'un devis"}
      </div>
      <div className='quote-simulation-step__actions'>
        {(quote.state === 'DEVIS EN COURS' ||
          quote.state === 'DEVIS ACCEPTÉ') && (
          <>
            <button
              type='button'
              className='button button-primary'
              onClick={handleSaveQuote}
              disabled={
                !customerIsUpdated && !repairIsUpdated && !vehicleIsUpdated
              }
            >
              Sauvegarder
            </button>
          </>
        )}
        {(quote.state === 'DEVIS EN COURS' ||
          quote.state === 'DEVIS ACCEPTÉ') &&
          quote.id !== null && (
            <>
              <button
                type='button'
                className='button button-primary'
                onClick={handleSendEmail}
                disabled={quote.state === 'SIMULATION' || isMailSent}
              >
                Envoyer par email
              </button>
              <button
                type='button'
                className='button button-primary'
                onClick={() => setShowPdf(true)}
              >
                Afficher PDF
              </button>

              <Modal
                title='Pdf'
                onClose={() => setShowPdf(false)}
                show={showPdf}
              >
                <iframe
                  src={`${process.env.REACT_APP_HTML_URL}/${quote.id}`}
                  frameBorder='0'
                  title='PDF'
                />
              </Modal>
            </>
          )}
      </div>

      <div className='quote-simulation-step__customer-vehicle'>
        <div className={quote.customer.vip ? 'border-color:vip color:vip' : ''}>
          <div>
            Client{' '}
            <button
              type='button'
              onClick={handleCustomerVip}
              disabled={quote.state === 'SIMULATION'}
            >
              <img src={quote.customer.vip ? Vip : NoVip} alt='' />
            </button>
          </div>
          <div>{`${quote.customer.firstname} ${quote.customer.lastname}`}</div>
          <div>{quote.customer.businessName}</div>
          <div>{quote.customer.email}</div>
          <div>{quote.customer.phone}</div>
          {(quote.state === 'DEVIS EN COURS' || quote.id === null) && (
            <>
              <button
                type='button'
                className='button button-secondary quote-simulation-step__edit'
                onClick={() => setShowCustomerManager(true)}
              >
                Modifier
              </button>

              <Modal
                title='Client'
                onClose={() => setShowCustomerManager(false)}
                show={showCustomerManager}
              >
                <CustomerManager
                  customer={quote.customer}
                  setCustomer={updateCustomer}
                  setIsValidCustomer={setIsValidCustomer}
                  validateField={validateField}
                />
              </Modal>
            </>
          )}
        </div>
        <div>
          <div>Véhicule</div>
          <div>
            <span>{category}</span>
          </div>
          <div>{`${quote.vehicle.manufacturerName} ${quote.vehicle.modelName}`}</div>
          <div>{quote.vehicle.plate}</div>
          {(quote.state === 'DEVIS EN COURS' || quote.id === null) && (
            <>
              <button
                type='button'
                className='button button-secondary quote-simulation-step__edit'
                onClick={() => setShowVehicleManager(true)}
              >
                Modifier
              </button>

              <Modal
                title='Véhicule'
                onClose={() => setShowVehicleManager(false)}
                show={showVehicleManager}
              >
                <VehicleManager
                  vehicle={quote.vehicle}
                  setVehicle={updateVehicle}
                  setIsValidVehicle={setIsValidVehicle}
                  validateField={validateField}
                />
              </Modal>
            </>
          )}
        </div>
      </div>

      <div className='quote-simulation-step__repairs'>
        <div>
          RÉPARATIONS DEMANDÉES
          {(quote.state === 'DEVIS EN COURS' || quote.id === null) && (
            <>
              <button
                type='button'
                className='button button-secondary quote-simulation-step__edit'
                onClick={() => setShowRepairManager(true)}
                disabled={quote.vehicle.category === null}
              >
                Modifier
              </button>

              <Modal
                title='Réparations'
                onClose={() => setShowRepairManager(false)}
                show={showRepairManager}
              >
                <RepairManager
                  repairs={quote.items}
                  vehicle={quote.vehicle}
                  setRepairs={updateRepairs}
                  setIsValidRepair={setIsValidRepair}
                  validateField={validateField}
                />
              </Modal>
            </>
          )}
        </div>
        <table>
          <colgroup>
            <col span='1' style={{ width: '45%' }} />
            <col span='1' style={{ width: '15%' }} />
            <col span='1' style={{ width: '10%' }} />
            <col span='1' style={{ width: '15%' }} />
            <col span='1' style={{ width: '15%' }} />
          </colgroup>
          <thead>
            <tr>
              <th>Intervention</th>
              <th>Prix unitaire TTC</th>
              <th>Unités</th>
              <th>Prix Total HT</th>
              <th>Prix Total TTC</th>
            </tr>
          </thead>
          <tbody>
            {quote.items !== [] &&
              quote.items.map(item => (
                <tr key={item.id}>
                  <td>{item.description}</td>
                  <td>{parseFloat(item.unitPrice).toFixed(2)} €</td>
                  <td>{parseInt(item.quantity, 10)}</td>
                  <td>
                    {`${parseFloat(
                      convertTtc2Ht(
                        item.unitPrice * item.quantity,
                        item.taxPercentage,
                      ),
                    ).toFixed(2)} €`}
                  </td>
                  <td>
                    {parseFloat(item.unitPrice * item.quantity).toFixed(2)} €
                  </td>
                </tr>
              ))}
          </tbody>
          <tfoot>
            <tr>
              <td>Total</td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td>{parseFloat(quote.calculatedTotalPrice).toFixed(2)} €</td>
            </tr>
          </tfoot>
        </table>
      </div>
      {Object.values(resources).length > 0 && (
        <div className='quote-simulation-step__resources'>
          <h3>Images</h3>
          <div>
            {Object.values(resources).map(resource => (
              <>
                <img
                  src={`${process.env.REACT_APP_DOMAIN_URL}${resource}`}
                  alt=''
                  onClick={() => {
                    setShowResource(true);
                    setResourceToShow(
                      `${process.env.REACT_APP_DOMAIN_URL}${resource}`,
                    );
                  }}
                />
              </>
            ))}
            <Modal
              title='Image'
              onClose={() => {
                setShowResource(false);
                setResourceToShow(null);
              }}
              show={showResource}
            >
              {resourceToShow !== null && <img src={resourceToShow} alt='' />}
            </Modal>
          </div>
        </div>
      )}
      <div className='quote-simulation-step__actions'>
        {quote.state === 'SIMULATION' && (
          <button
            type='button'
            className='button button-primary'
            onClick={handleConvertSimulationQuoteToInProgressQuote}
          >
            Convertir en devis
          </button>
        )}
        {quote.state === 'DEVIS EN COURS' && quote.id && (
          <>
            <div className='quote-simulation-step__cgu'>
              <input
                type='checkbox'
                checked={isValidCgu}
                onClick={() => setIsValidCgu(!isValidCgu)}
              />
              <label>
                J&apos;accepte les{' '}
                <span onClick={() => setShowCgu(true)}>
                  conditions générales
                </span>
              </label>

              <Modal
                title='Conditions générales'
                onClose={() => setShowCgu(false)}
                show={showCgu}
              >
                <iframe
                  src={`${process.env.REACT_APP_DOMAIN_URL}/cgu`}
                  frameBorder='0'
                  title='CGU'
                />
              </Modal>
            </div>
            <div className='quote-simulation-step__signature-wrapper'>
              <SignaturePad
                ref={signatureRef}
                height={158}
                options={{ penColor: 'rgb(0, 0, 0)' }}
              />
            </div>
            <button
              type='button'
              onClick={() => {
                signatureRef.current.instance.clear();
                signatureRef.current.instance.addEventListener(
                  'endStroke',
                  () =>
                    setIsValidSignature(
                      !signatureRef.current.instance.isEmpty(),
                    ),
                );
                setIsValidSignature(false);
              }}
            >
              <img src={Trash} alt='Effacer la signature' />
            </button>
            <button
              type='button'
              className='button button-primary'
              onClick={handleConvertInProgressQuoteToAcceptedQuote}
              disabled={
                !isValidCustomer ||
                !isValidRepair ||
                !isValidVehicle ||
                !isValidSignature ||
                !isValidCgu ||
                customerIsUpdated ||
                repairIsUpdated ||
                vehicleIsUpdated
              }
            >
              Accepter le devis
            </button>
          </>
        )}
        {quote.state === 'DEVIS EN COURS' && quote.id === null && (
          <>
            <button
              type='button'
              className='button button-primary'
              onClick={handleCreateInProgressQuote}
              disabled={
                !isValidCustomer ||
                !isValidRepair ||
                !isValidVehicle ||
                customerIsUpdated ||
                repairIsUpdated ||
                vehicleIsUpdated
              }
            >
              Créer le devis
            </button>
          </>
        )}

        {quote.state === 'DEVIS ACCEPTÉ' && quote.signatureImage !== null && (
          <>
            <div>
              {`Signé le ${moment(quote.signatureImage.updatedAt).format(
                'DD/MM/YYYY',
              )} à ${moment(quote.signatureImage.updatedAt).format('HH:mm')}`}
            </div>
            <div className='quote-simulation-step__signature-wrapper'>
              <img
                src={`${process.env.REACT_APP_DOMAIN_URL}${quote.signatureImage.contentUrl}`}
                alt=''
              />
            </div>
          </>
        )}

        {quote.state === 'DEVIS ACCEPTÉ' && (
          <>
            {Object.values(quote.repairOrders).length > 0 && (
              <button
                type='button'
                className='button button-primary'
                onClick={() => {
                  showRepairOrder(quote.repairOrders);
                }}
              >
                Voir l&apos;OR
              </button>
            )}
            {Object.values(quote.repairOrders).length === 0 && (
              <button
                type='button'
                className='button button-primary'
                onClick={() => {
                  convertQuoteAcceptedToRepairOrder(quote);
                }}
              >
                Créer un OR
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SimulationStep;
