import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');

const Datepicker = props => {
  const { onChange } = props;

  const marginElement = 60;
  const heightElement = 28.2;
  const firstYear = moment().year() - 20;

  const [date, setDate] = useState(new Date());
  const daysRef = useRef();
  const monthsRef = React.useRef();
  const yearsRef = React.useRef();

  const getScrollTop = ref => {
    return ref.current.scrollTop;
  };

  const getClientHeight = ref => {
    return ref.current.clientHeight;
  };

  const getElement = ref => {
    return (
      parseInt(
        (getScrollTop(ref) + getClientHeight(ref) - marginElement) /
          heightElement,
        10,
      ) + 1
    );
  };

  const handleDayChange = () => {
    setDate(moment(date).set('date', getElement(daysRef)));
  };

  const handleMonthChange = () => {
    setDate(moment(date).set('month', getElement(monthsRef) - 1));
  };

  const handleYearChange = () => {
    setDate(moment(date).set('year', firstYear + getElement(yearsRef)));
  };

  useEffect(() => {
    daysRef.current.scrollTop = (moment(date).date() - 1) * heightElement;
    monthsRef.current.scrollTop = moment(date).month() * heightElement;
    yearsRef.current.scrollTop =
      (moment(date).year() - firstYear - 1) * heightElement;
  }, []);

  useEffect(() => {
    onChange(moment(date).format());
  }, [date]);

  return (
    <div className='datepicker'>
      <div
        className='datepicker__days'
        ref={daysRef}
        onScroll={handleDayChange}
      >
        {Array.from(Array(moment(date).daysInMonth())).map((e, i) => (
          <div
            key={i}
            className={moment(date).date() === i + 1 ? 'is-selected' : ''}
          >
            {i + 1}
          </div>
        ))}
      </div>

      <div
        className='datepicker__months'
        ref={monthsRef}
        onScroll={handleMonthChange}
      >
        {moment.months().map(month => (
          <div
            key={month}
            className={
              moment(date).month() + 1 ===
              parseInt(moment().month(month).format('M'), 10)
                ? 'is-selected'
                : ''
            }
          >
            {month}
          </div>
        ))}
      </div>

      <div
        className='datepicker__years'
        ref={yearsRef}
        onScroll={handleYearChange}
      >
        {Array.from(Array(40)).map((e, i) => (
          <div
            key={i}
            className={
              moment(date).year() === firstYear + (i + 1) ? 'is-selected' : ''
            }
          >
            {firstYear + (i + 1)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Datepicker;
