import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

const Modal = props => {
  const { children, onClose, show, title } = props;
  const closeOnEscapeKeyDown = e => {
    if ((e.charCode || e.keyCode) === 27) {
      onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  }, []);

  return ReactDOM.createPortal(
    <CSSTransition in={show} unmountOnExit timeout={{ enter: 0, exit: 300 }}>
      <div className='modal' onClick={onClose}>
        <div className='modal__content' onClick={e => e.stopPropagation()}>
          <div className='modal__header'>
            <h4>{title}</h4>
            <button type='button' onClick={onClose}>
              X
            </button>
          </div>
          <div className='modal__body'>{children}</div>
          <div className='modal__footer'>...</div>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById('root'),
  );
};

export default Modal;
