import React, { useEffect, useRef, useState } from 'react';
import Progressbar from 'components/Progressbar/Progressbar';
import SearchStep from 'components/Quote/Steps/SearchStep/SearchStep';
import SimulationStep from 'components/Quote/Steps/SimulationStep/SimulationStep';
import Logo from 'assets/images/logo.svg';

const Create = props => {
  const {
    convertQuoteAcceptedToRepairOrder,
    quote: quoteFromRepairOrder,
    showRepairOrder,
  } = props;

  const initialSteps = {
    index: 0,
    list: [
      {
        name: 'Recherche',
        isValid: false,
      },
      {
        name: 'Récapitulatif',
        isValid: false,
      },
      {
        name: 'Devis validé',
        isValid: false,
      },
    ],
  };

  const initialQuote = {
    quoteIri: null,
    id: null,
    customer: {
      '@id': null,
      id: null,
      firstname: '',
      lastname: '',
      businessName: '',
      email: '',
      phone: '',
      vip: false,
      street: '',
      zipCode: '',
      city: '',
      country: '',
    },
    vehicle: {
      '@id': null,
      id: null,
      plate: '',
      category: null,
      manufacturerName: '',
      modelName: '',
      model: null,
    },
    items: [],
    calculatedTotalPrice: 0,
    repairOrderVehicleImageToShow: '',
    state: '',
  };

  const [steps, setSteps] = useState(initialSteps);

  const [quote, setQuote] = useState(initialQuote);

  const signatureRef = useRef();

  useEffect(() => {
    if (quoteFromRepairOrder !== null) {
      setQuote(quoteFromRepairOrder);
      setSteps({
        index: steps.index + 1,
        list: {
          ...steps.list,
          [steps.index]: {
            ...steps.list[steps.index],
            isValid: true,
          },
          [steps.index + 1]: {
            ...steps.list[steps.index + 1],
            isValid: true,
          },
        },
      });
    }
  }, []);

  const renderStep = () => {
    switch (steps.list[steps.index]) {
      case steps.list[0]:
        return (
          <SearchStep
            quote={quote}
            setQuote={setQuote}
            steps={steps}
            setSteps={setSteps}
          />
        );
      case steps.list[1]:
        return (
          <SimulationStep
            quote={quote}
            setQuote={setQuote}
            steps={steps}
            setSteps={setSteps}
            signatureRef={signatureRef}
            convertQuoteAcceptedToRepairOrder={
              convertQuoteAcceptedToRepairOrder
            }
            showRepairOrder={showRepairOrder}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className='app'>
      <header className='app__header'>
        <a href='/'>
          <img src={Logo} alt='Fix Control' />
        </a>
        <Progressbar steps={steps} setSteps={setSteps} />
      </header>

      <div className='app__body'>{renderStep()}</div>
    </div>
  );
};

export default Create;
