/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import Modal from 'components/Modal/Modal';
import RepairOrdersApi from 'services/api/RepairOrdersApi';
import moment from 'moment';
import 'moment/locale/fr';
import Input from 'components/Form/Input';
import Logo from 'assets/images/logo.svg';

const List = props => {
  const { repairOrderId } = props;
  const [repairOrders, setRepairOrders] = useState([]);
  const [repairOrderSelected, setRepairOrderSelected] = useState(null);
  const [totalItems, setTotalItems] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderByCreatedAt, setOrderByCreatedAt] = useState('');
  const [orderByEstimatedAt, setOrderByEstimatedAt] = useState('');

  const [showPdf, setShowPdf] = useState(false);
  const [showRepairOrderClose, setShowRepairOrderClose] = useState(false);
  const [repairOrderArchived, setRepairOrderArchived] = useState(false);
  const [initialization, setInitialization] = useState(repairOrderId !== null);

  const [search, setSearch] = useState('');

  const fetchRepairOrders = async () => {
    const result =
      search !== '' || initialization
        ? await RepairOrdersApi.search(initialization ? repairOrderId : search)
        : await RepairOrdersApi.findAll(
            currentPage,
            repairOrderArchived,
            orderByCreatedAt,
            orderByEstimatedAt,
          );

    setRepairOrders(result.items);
    setTotalItems(result.totalItems);
    setTotalPage(
      Math.ceil(result.totalItems / Object.values(result.items).length),
    );
  };

  const manageSortCycle = currentOrder => {
    switch (currentOrder) {
      case '':
        return 'ASC';
      case 'ASC':
        return 'DESC';
      case 'DESC':
        return '';
      default:
        return '';
    }
  };

  const handleRepairOrderCloseOpenModal = repairOrderToCloseLocal => {
    setShowRepairOrderClose(true);

    const itemsToCheck = [
      {
        name: 'Voiture lavée',
        isChecked: false,
        editable: true,
      },
    ];

    Object.values(repairOrderToCloseLocal.quote.items).forEach(itemToCheck => {
      itemsToCheck.unshift({
        ...itemToCheck,
        isChecked: !itemToCheck.editable,
        editable: itemToCheck.editable,
      });
    });

    setRepairOrderSelected({
      ...repairOrderToCloseLocal,
      quote: {
        ...repairOrderToCloseLocal.quote,
        items: itemsToCheck,
      },
    });
  };

  const handleCloseRepairOrder = async () => {
    await RepairOrdersApi.put(repairOrderSelected.id, {
      closureDate: moment().format(),
    });
    setRepairOrderSelected(null);
    setShowRepairOrderClose(false);
    fetchRepairOrders();
  };

  const handleSearchChange = ({ currentTarget }) => {
    const { value } = currentTarget;

    setSearch(value);
  };

  useEffect(() => {
    if (repairOrderId !== null) {
      setSearch(repairOrderId);
    }

    fetchRepairOrders();
  }, []);

  useEffect(() => {
    if (initialization) {
      setInitialization(false);
    } else {
      fetchRepairOrders();
    }
  }, [currentPage, repairOrderArchived, orderByCreatedAt, orderByEstimatedAt]);

  return (
    <div className='app'>
      <header className='app__header'>
        <a href='/'>
          <img src={Logo} alt='Fix Control' />
        </a>
        <div className='app__header--search'>
          <div>
            <label htmlFor='repairOrderArchived'>
              Afficher les OR archivés
            </label>
            <input
              id='repairOrderArchived'
              type='checkbox'
              name='repairOrderArchived'
              label='Afficher les OR archivés'
              checked={repairOrderArchived}
              onChange={() => {
                setRepairOrderArchived(!repairOrderArchived);
              }}
            />
          </div>
          <Input name='search' value={search} onChange={handleSearchChange} />
          <button
            type='button'
            className='button button-primary'
            onClick={fetchRepairOrders}
          >
            Rechercher
          </button>
        </div>
      </header>

      <div className='app__body'>
        <div className='table'>
          <table className='table__content'>
            <thead>
              <tr>
                <th>
                  <div>Id</div>
                </th>
                <th>
                  <button
                    type='button'
                    onClick={() => {
                      setOrderByCreatedAt(manageSortCycle(orderByCreatedAt));
                      setOrderByEstimatedAt('');
                    }}
                  >
                    Créé le {orderByCreatedAt === 'ASC' && <>&#8593;</>}
                    {orderByCreatedAt === 'DESC' && <>&#8595;</>}
                  </button>
                </th>
                <th>
                  <div>Devis associé</div>
                </th>
                <th>
                  <div>Montant du devis</div>
                </th>
                <th>
                  <button
                    type='button'
                    onClick={() => {
                      setOrderByEstimatedAt(
                        manageSortCycle(orderByEstimatedAt),
                      );
                      setOrderByCreatedAt('');
                    }}
                  >
                    Date de fin de travaux estimée{' '}
                    {orderByEstimatedAt === 'ASC' && <>&#8593;</>}
                    {orderByEstimatedAt === 'DESC' && <>&#8595;</>}
                  </button>
                </th>
                <th>
                  <div>Actions</div>
                </th>
              </tr>
            </thead>

            <tbody>
              {Object.values(repairOrders).map(repairOrder => (
                <tr key={repairOrder.id}>
                  <td>
                    <div>{repairOrder.id}</div>
                  </td>
                  <td>
                    <div>
                      {moment(repairOrder.createdAt).format('DD/MM/YYYY')}
                    </div>
                  </td>
                  <td>
                    <div>
                      {`${repairOrder.quote.id} - ${repairOrder.quote.customer.firstname} ${repairOrder.quote.customer.lastname} - ${repairOrder.quote.vehicle.plate}`}
                    </div>
                  </td>
                  <td>
                    <div>{`${repairOrder.quote.calculatedTotalPrice} €`}</div>
                  </td>
                  <td>
                    <div>
                      {moment(repairOrder.estimatedDate).format('DD/MM/YYYY')}
                    </div>
                  </td>
                  <td>
                    <div>
                      <button
                        type='button'
                        className='button button-primary'
                        onClick={() => {
                          setRepairOrderSelected(repairOrder);
                          setShowPdf(true);
                        }}
                      >
                        Afficher
                      </button>
                      {repairOrder.closureDate === null && (
                        <button
                          type='button'
                          className='button button-primary'
                          onClick={() => {
                            handleRepairOrderCloseOpenModal(repairOrder);
                          }}
                        >
                          Clôturer
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {totalItems > 0 && totalPage > 1 && (
            <div className='table__navigation'>
              {Array.from({ length: totalPage }, (value, index) => {
                return (
                  <button
                    type='button'
                    className='button button--navigation-table-page'
                    onClick={() => {
                      setCurrentPage(index + 1);
                    }}
                  >
                    {index + 1}
                  </button>
                );
              })}
            </div>
          )}

          <Modal
            title={`Clôturer l'ordre de réparation ${
              repairOrderSelected !== null ? repairOrderSelected.id : ''
            }`}
            onClose={() => {
              setShowRepairOrderClose(false);
              setRepairOrderSelected(null);
            }}
            show={showRepairOrderClose}
          >
            {repairOrderSelected !== null && (
              <div className='repair-order-list'>
                <div>
                  <h3>Réparations demandées :</h3>
                  <p>Merci de vérifier que les réparations sont effectuées</p>
                  {Object.values(repairOrderSelected.quote.items).map(
                    (itemToCheck, index) => (
                      <div>
                        <input
                          id={`${index}.${itemToCheck.name}`}
                          type='checkbox'
                          checked={itemToCheck.isChecked}
                          disabled={!itemToCheck.editable}
                          onClick={() => {
                            setRepairOrderSelected({
                              ...repairOrderSelected,
                              quote: {
                                ...repairOrderSelected.quote,
                                items: {
                                  ...repairOrderSelected.quote.items,
                                  [index]: {
                                    ...repairOrderSelected.quote.items[index],
                                    isChecked:
                                      !repairOrderSelected.quote.items[index]
                                        .isChecked,
                                  },
                                },
                              },
                            });
                          }}
                        />
                        <label htmlFor={`${index}.${itemToCheck.name}`}>
                          {itemToCheck.name}
                        </label>
                      </div>
                    ),
                  )}

                  <button
                    type='button'
                    className='button button-primary'
                    onClick={handleCloseRepairOrder}
                    disabled={
                      Object.values(repairOrderSelected.quote.items).filter(
                        itemToCheck => itemToCheck.isChecked === false,
                      ).length > 0
                    }
                  >
                    CLOTURER
                  </button>
                </div>
              </div>
            )}
          </Modal>

          <Modal
            title='Pdf'
            onClose={() => {
              setRepairOrderSelected(null);
              setShowPdf(false);
            }}
            show={showPdf}
          >
            {repairOrderSelected !== null && (
              <iframe
                src={`${process.env.REACT_APP_OR_PDF_URL}/${repairOrderSelected.id}/pdf-file`}
                frameBorder='0'
                title='PDF'
              />
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default List;
