import React, { useEffect, useState } from 'react';
import Input from 'components/Form/Input';
import TaskPricesApi from 'services/api/TaskPricesApi';

const Collection = props => {
  const { repairs, setRepairs, setIsValidRepair, vehicle } = props;

  const repairSkeleton = [
    {
      editable: true,
      name: 'description',
      list: 'tasks',
      type: 'text',
    },
    {
      editable: true,
      name: 'unitPrice',
      type: 'number',
    },
    {
      editable: false,
      name: 'unitPriceHt',
    },
    {
      editable: true,
      name: 'quantity',
      type: 'number',
    },
    {
      editable: true,
      name: 'taxPercentage',
      type: 'number',
    },
    {
      editable: false,
      name: 'total',
    },
  ];

  const [collection, setCollection] = useState([]);

  const [collectionTaskPrices, setCollectionTaskPrices] = useState([]);

  const [repairTotal, setRepairTotal] = useState(parseInt(0, 10));

  const [canSave, setCanSave] = useState(false);

  const renderValueFormatted = (name, value) => {
    if (['unitPriceHt', 'total'].includes(name)) {
      return `${parseFloat(value).toFixed(2)} €`;
    }

    if (['unitPrice', 'taxPercentage'].includes(name)) {
      return parseFloat(value);
    }

    if (name === 'quantity') {
      return parseInt(value, 10);
    }

    return value;
  };

  const fetchTaskPrices = async (collectionIndex, searchData) => {
    const taskPrices = await TaskPricesApi.search(
      vehicle.category.split('/').pop(),
      searchData,
    );

    setCollectionTaskPrices({
      ...collectionTaskPrices,
      [collectionIndex]: taskPrices,
    });
  };

  const handleChange = ({ currentTarget }) => {
    const { name } = currentTarget;
    let { value } = currentTarget;
    const nameSplit = name.split('.');
    const collectionItem = collection[nameSplit[0]];

    let taskPriceIri = collectionItem['@id'];
    let unitPriceHtLocal = collectionItem.unitPriceHt;
    let totalLocal = collectionItem.total;

    if (nameSplit[1] === 'unitPrice') {
      value = parseFloat(value);
      taskPriceIri = null;
      unitPriceHtLocal =
        parseFloat(value) /
        (1 + parseFloat(collectionItem.taxPercentage) / 100);
      totalLocal = parseFloat(value) * parseInt(collectionItem.quantity, 10);
    } else if (nameSplit[1] === 'quantity') {
      value = parseInt(value, 10);
      totalLocal = parseInt(value, 10) * parseFloat(collectionItem.unitPrice);
    } else if (nameSplit[1] === 'taxPercentage') {
      value = parseFloat(value);
      unitPriceHtLocal =
        parseFloat(collectionItem.unitPrice) / (1 + parseFloat(value) / 100);
    } else if (nameSplit[1] === 'description') {
      taskPriceIri = null;
      fetchTaskPrices(nameSplit[0], value);
    }

    setCollection({
      ...collection,
      [nameSplit[0]]: {
        ...collection[nameSplit[0]],
        [nameSplit[1]]: value,
        '@id': taskPriceIri,
        unitPriceHt: parseFloat(unitPriceHtLocal),
        total: parseFloat(totalLocal),
      },
    });
  };

  const handleAdd = () => {
    const collectionCopy = Object.values({ ...collection });
    collectionCopy.push({
      '@id': null,
      description: '',
      unitPrice: parseFloat(0),
      unitPriceHt: parseFloat(0),
      quantity: parseInt(1, 10),
      taxPercentage: parseFloat(20),
      total: parseFloat(0),
      editable: true,
      removable: true,
    });
    setCollection(collectionCopy);

    const collectionTaskPricesCopy = Object.values({ ...collectionTaskPrices });
    collectionTaskPricesCopy.push([]);
    setCollectionTaskPrices(collectionTaskPricesCopy);
  };

  const handleRemove = index => {
    const collectionCopy = Object.values({ ...collection });
    collectionCopy.splice(index, 1);
    setCollection(collectionCopy);

    const collectionTaskPricesCopy = Object.values({ ...collectionTaskPrices });
    collectionTaskPricesCopy.splice(index, 1);
    setCollectionTaskPrices(collectionTaskPricesCopy);
  };

  const handleTaskSelected = (collectionId, task) => {
    fetchTaskPrices(collectionId, task.description);
    setCollection({
      ...collection,
      [collectionId]: {
        ...collection[collectionId],
        '@id': task['@id'],
        description: task.name,
        unitPrice: parseFloat(task.price),
        unitPriceHt: parseFloat(
          parseFloat(task.price) /
            (1 + parseFloat(collection[collectionId].taxPercentage) / 100),
        ),
        total: parseFloat(
          parseFloat(task.price) *
            parseInt(collection[collectionId].quantity, 10),
        ),
        editable: task.editable,
        removable: task.removable,
      },
    });
  };

  useEffect(() => {
    let isValid = true;
    let totalTtc = 0;

    Object.values(collection).forEach(item => {
      if (
        isValid &&
        (item.description === '' ||
          item.unitPrice === '' ||
          item.quantity === '')
      ) {
        isValid = false;
      }

      if (item.unitPrice !== '' && item.quantity !== '') {
        totalTtc += parseFloat(item.total);
      }
    });

    setRepairTotal(parseFloat(totalTtc));
    setCanSave(isValid);
    setIsValidRepair(isValid);
  }, [collection]);

  useEffect(() => {
    if (collection.length === 0 && repairs.length > 0) {
      const collectionLocal = [];
      Object.values(repairs).forEach(repair => {
        collectionLocal.push({
          '@id': repair.taskPrice,
          name: repair.name,
          description: repair.description,
          unitPrice: parseFloat(repair.unitPrice),
          unitPriceHt: parseFloat(
            parseFloat(repair.unitPrice) /
              (1 + parseFloat(repair.taxPercentage) / 100),
          ),
          quantity: parseInt(repair.quantity, 10),
          taxPercentage: parseFloat(repair.taxPercentage),
          total: parseFloat(
            parseFloat(repair.unitPrice) * parseInt(repair.quantity, 10),
          ),
          editable: repair.editable,
          removable: repair.removable,
        });
      });
      setCollection(collectionLocal);
    }
  }, [repairs]);

  return (
    <div className='quote-repair-manage'>
      <form>
        <table>
          <colgroup>
            <col span='1' style={{ width: '50%' }} />
            <col span='1' style={{ width: '10%' }} />
            <col span='1' style={{ width: '10%' }} />
            <col span='1' style={{ width: '10%' }} />
            <col span='1' style={{ width: '10%' }} />
            <col span='1' style={{ width: '10%' }} />
          </colgroup>
          <thead>
            <tr>
              <th>Intervention</th>
              <th>P.U TTC</th>
              <th>P.U HT</th>
              <th>Qté</th>
              <th>TVA</th>
              <th>TTC</th>
            </tr>
          </thead>
          <tbody>
            {Object.values({ ...collection }).map((collectionData, index) => (
              <tr key={index}>
                {repairSkeleton.map(item => (
                  <td>
                    {item.editable && (
                      <div className='quote-repair-manage__edit-with-unit'>
                        <Input
                          key={item.name}
                          list={item.list ? `${index}.${item.list}` : ''}
                          name={`${index}.${item.name}`}
                          onChange={e => {
                            if (item.list) {
                              const inputList = document.getElementById(
                                `${index}.${item.list}`,
                              );

                              if (inputList !== null) {
                                inputList.style.display = 'block';
                              }
                            }

                            handleChange(e);
                          }}
                          onFocus={() => {
                            if (item.list) {
                              const inputList = document.getElementById(
                                `${index}.${item.list}`,
                              );

                              if (inputList !== null) {
                                inputList.style.display = 'block';
                              }
                            }
                          }}
                          onBlur={() => {
                            setTimeout(() => {
                              if (item.list) {
                                const inputList = document.getElementById(
                                  `${index}.${item.list}`,
                                );
                                if (inputList !== null) {
                                  inputList.style.display = 'none';
                                }
                              }
                            }, 250);
                          }}
                          type={item.type}
                          value={renderValueFormatted(
                            item.name,
                            collection[index][item.name],
                          )}
                          disabled={!collection[index].editable}
                        />
                        {item.name === 'unitPrice' && <> €</>}
                        {item.name === 'taxPercentage' && <> %</>}
                      </div>
                    )}
                    {item.list && collectionTaskPrices[index] !== undefined && (
                      <div id={`${index}.${item.list}`} className='input-list'>
                        {collectionTaskPrices[index] &&
                          collectionTaskPrices[index].map(task => (
                            <button
                              type='button'
                              onClick={() => handleTaskSelected(index, task)}
                            >
                              {task.name}
                            </button>
                          ))}
                      </div>
                    )}
                    {!item.editable &&
                      renderValueFormatted(
                        item.name,
                        collection[index][item.name],
                      )}
                  </td>
                ))}
                {collection[index].removable && (
                  <button
                    type='button'
                    onClick={() => handleRemove(index)}
                    className='button button-secondary remove'
                  >
                    -
                  </button>
                )}
              </tr>
            ))}
            <tr>
              <td colSpan='6'>
                <button
                  type='button'
                  onClick={handleAdd}
                  className='button button-primary add'
                >
                  +
                </button>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>Total</td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td>{parseFloat(repairTotal).toFixed(2)} €</td>
            </tr>
          </tfoot>
        </table>
      </form>
      <button
        type='button'
        className='button button-primary'
        onClick={() => {
          setRepairs(collection, parseFloat(repairTotal));
        }}
        disabled={!canSave}
      >
        Enregistrer
      </button>
    </div>
  );
};

export default Collection;
