import Api from './Api';

const URL = 'task_prices';

const search = async (vehicleCategoryId, searchData) =>
  Api.get(`${URL}?category=${vehicleCategoryId}&task.name=${searchData}`).then(
    response => response.data['hydra:member'],
  );

export default {
  search,
};
