import Api from './Api';

const URL = 'categories';

const findAll = async () =>
  Api.get(URL).then(response => response.data['hydra:member']);

const findOne = async id =>
  Api.get(`${URL}/${id}`).then(response => response.data);

export default {
  findAll,
  findOne,
};
