import React, { useEffect, useState } from 'react';
import Input from 'components/Form/Input';
import CustomersApi from 'services/api/CustomersApi';
import SearchIcon from 'assets/images/icons/search.svg';

const Manage = props => {
  const { customer, setCustomer, setIsValidCustomer, validateField } = props;

  const [customerLocal, setCustomerLocal] = useState(customer);

  const [searchMode, setSearchMode] = useState(false);

  const [canSave, setCanSave] = useState(false);

  const [isUniqueEmail, setIsUniqueEmail] = useState(true);

  const [search, setSearch] = useState({
    text: '',
    result: [],
  });

  const customerFields = {
    firstname: {
      name: 'firstname',
      label: 'Prénom',
      fieldType: 'input',
      attributType: 'text',
      value: customer.firstname,
      validation: ['required'],
    },
    lastname: {
      name: 'lastname',
      label: 'Nom de famille',
      fieldType: 'input',
      attributType: 'text',
      value: customer.lastname,
      validation: ['required'],
    },
    businessName: {
      name: 'businessName',
      label: 'Société',
      fieldType: 'input',
      attributType: 'text',
      value: customer.businessName,
      validation: [],
    },
    email: {
      name: 'email',
      label: 'Email',
      fieldType: 'input',
      attributType: 'email',
      value: customer.email,
      validation: ['required', 'email'],
    },
    phone: {
      name: 'phone',
      label: 'Téléphone mobile',
      fieldType: 'input',
      attributType: 'tel',
      value: customer.phone,
      validation: ['required'],
    },
    street: {
      name: 'street',
      label: 'Rue',
      fieldType: 'input',
      attributType: 'text',
      value: customer.street,
      validation: [],
    },
    zipCode: {
      name: 'zipCode',
      label: 'Code postal',
      fieldType: 'input',
      attributType: 'text',
      value: customer.zipCode,
      validation: [],
    },
    city: {
      name: 'city',
      label: 'Ville',
      fieldType: 'input',
      attributType: 'text',
      value: customer.city,
      validation: [],
    },
    country: {
      name: 'country',
      label: 'Pays',
      fieldType: 'input',
      attributType: 'text',
      value: customer.country,
      validation: [],
    },
  };

  const checkCustomerEmail = async () => {
    const customers = await CustomersApi.search(customerLocal.email);
    setIsUniqueEmail(
      Object.values(customers).length === 0
        ? true
        : customers[0].id === customerLocal.id,
    );
  };

  const handleCustomerLocalChange = ({ currentTarget }) => {
    const { value, name } = currentTarget;

    setCustomerLocal({
      ...customerLocal,
      [name]: value,
    });
  };

  const handleSearchChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;

    setSearch({ ...search, [name]: value });
  };

  const handleSearch = async () => {
    if (search.text === '') {
      return;
    }

    try {
      const customersResult = await CustomersApi.search(search.text);

      setSearch({
        ...search,
        result: customersResult,
      });
    } catch (error) {
      setSearch({ ...search, result: [] });
      alert('Aucun résultat');
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleCustomerSelected = customerSelected => {
    setCustomerLocal({
      '@id': customerSelected['@id'],
      id: customerSelected.id,
      firstname: customerSelected.firstname,
      lastname: customerSelected.lastname,
      businessName: customerSelected.businessName,
      email: customerSelected.email,
      phone: customerSelected.phone,
      street: customerSelected.street,
      zipCode: customerSelected.zipCode,
      city: customerSelected.city,
      country: customerSelected.country,
      vip: customerSelected.vip,
    });
    setSearchMode(false);
  };

  const renderFieldValue = name => {
    switch (name) {
      case 'firstname':
        return customerLocal.firstname;
      case 'lastname':
        return customerLocal.lastname;
      case 'businessName':
        return customerLocal.businessName;
      case 'email':
        return customerLocal.email;
      case 'phone':
        return customerLocal.phone;
      case 'street':
        return customerLocal.street;
      case 'zipCode':
        return customerLocal.zipCode;
      case 'city':
        return customerLocal.city;
      case 'country':
        return customerLocal.country;
      default:
        return null;
    }
  };

  useEffect(() => {
    const isValid =
      Object.values(customerFields).filter(field => {
        let validationRules = field.validation;

        if (
          field.name === 'businessName' &&
          customerLocal.firstname === '' &&
          customerLocal.lastname === ''
        ) {
          validationRules = ['required'];
        }

        if (
          field.name === 'email' &&
          validateField(renderFieldValue(field.name), validationRules) &&
          (customer.id === null || customer.email !== customerLocal.email)
        ) {
          checkCustomerEmail();
        }

        return !validateField(renderFieldValue(field.name), validationRules);
      }).length === 0;
    setCanSave(isValid);
    setIsValidCustomer(isValid);
  }, [customerLocal]);

  return (
    <div className='quote-customer-manage'>
      {searchMode && (
        <div>
          <div className='search-step'>
            <div className='step step--search'>
              <div className='step__title'>
                <span>Trouver un client</span>{' '}
                <img src={SearchIcon} alt='Icone loupe' />
                <button
                  type='button'
                  className='button button-primary'
                  onClick={() => {
                    setSearchMode(false);
                  }}
                >
                  Retour
                </button>
              </div>
              <p className='step__paragraph'>Prénom, Nom</p>
              <div className='search-step__form'>
                <Input
                  name='text'
                  value={search.text}
                  onChange={handleSearchChange}
                  onKeyDown={handleKeyDown}
                />
                <button
                  type='button'
                  className='button button-primary'
                  onClick={handleSearch}
                  disabled={search.text === ''}
                >
                  Chercher
                </button>
              </div>
              <div className='search-step__results'>
                {search.result !== [] &&
                  search.result.map(customerResult => (
                    <div
                      key={customerResult.id}
                      className='card card--search'
                      onClick={() => handleCustomerSelected(customerResult)}
                    >
                      <div>{`${customerResult.firstname} ${customerResult.lastname}`}</div>
                      <div>{customerResult.businessName}</div>
                      <div>{customerResult.email}</div>
                      <div>{customerResult.phone}</div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {!searchMode && (
        <form className='quote-customer-manage__form'>
          {Object.values(customerFields).map(field => (
            <div>
              <Input
                key={field.name}
                name={field.name}
                label={field.label}
                value={renderFieldValue(field.name)}
                type={field.attributType}
                onChange={handleCustomerLocalChange}
                required={Object.values(field.validation).includes('required')}
              />
              {!isUniqueEmail && field.name === 'email' && (
                <div className='input-error'>
                  Email déjà associé à un client
                </div>
              )}
            </div>
          ))}
          <div>
            {customer.id === null && (
              <button
                type='button'
                className='button button-primary'
                onClick={() => {
                  setSearchMode(true);
                }}
              >
                Chercher un client
              </button>
            )}
            <button
              type='button'
              className='button button-primary'
              onClick={() => {
                setCustomer(customerLocal);
              }}
              disabled={!isUniqueEmail || !canSave}
            >
              Enregistrer
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default Manage;
