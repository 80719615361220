import Api from './Api';

const URL = 'models';

const search = async (searchData, firstResult = false) =>
  Api.get(`${URL}?query=${searchData}`).then(response => {
    const results = response.data['hydra:member'];

    try {
      return firstResult
        ? Object.values(results).filter(result => result.name === searchData)[0]
        : results;
    } catch (error) {
      return null;
    }
  });

export default {
  search,
};
