/* eslint-disable no-alert */
import React, { useEffect, useRef, useState } from 'react';
import Progressbar from 'components/Progressbar/Progressbar';
import QuoteStep from 'components/RepairOrder/Steps/QuoteStep/QuoteStep';
import CheckupStep from 'components/RepairOrder/Steps/CheckupStep/CheckupStep';
import RepairStep from 'components/RepairOrder/Steps/RepairStep/RepairStep';
import SignatureStep from 'components/RepairOrder/Steps/SignatureStep/SignatureStep';
import Validation from 'utils/validation';
import RepairOrdersApi from 'services/api/RepairOrdersApi';
import SignatureConvert from 'utils/signatureConvert';
import Logo from 'assets/images/logo.svg';

const Create = props => {
  const { quote, showQuote, showRepairOrder } = props;

  const initialSteps = {
    index: 0,
    list: [
      {
        name: 'trouver un devis',
        isValid: false,
      },
      {
        name: 'état des lieux',
        isValid: false,
      },
      {
        name: 'réparation',
        isValid: false,
      },
      {
        name: 'signature',
        isValid: false,
      },
    ],
  };

  const initialRepairOrder = {
    quoteIri: '',
    customer: {
      '@id': '',
      id: '',
      firstname: '',
      lastname: '',
      businessName: '',
      email: '',
      phone: '',
    },
    vehicle: {
      '@id': '',
      id: '',
      plate: '',
      category: '',
      manufacturerName: '',
      modelName: '',
    },
    repairsRequested: [],
    repairOrderVehicleImageToShow: '',
    uiMarkers: [],
    repairs: [],
    partsToOrder: false,
    calculatedTotalPrice: '',
    estimatedDate: new Date(),
    note: '',
  };

  const [steps, setSteps] = useState(initialSteps);
  const [repairOrder, setRepairOrder] = useState(initialRepairOrder);
  const signatureRef = useRef();

  const validateField = (value, rules) => {
    let isValid = true;

    if (rules !== []) {
      rules.forEach(rule => {
        if (isValid) {
          if (rule === 'required' && value === '') {
            isValid = false;
          }

          if (rule === 'email' && !Validation.isEmail(value)) {
            isValid = false;
          }
        }
      });
    }

    return isValid;
  };

  const handleSubmit = async () => {
    try {
      const data = {
        quote: repairOrder.quoteIri,
        note: repairOrder.note,
        calculatedTotalPrice: parseFloat(repairOrder.calculatedTotalPrice),
        partsToOrder: repairOrder.partsToOrder,
        estimatedDate: repairOrder.estimatedDate,
        uiMarkers: repairOrder.uiMarkers,
      };

      const repairOrderId = await RepairOrdersApi.create(data);
      const signatureDataUrlSplit = signatureRef.current.toDataURL().split(',');
      RepairOrdersApi.attachSignature(
        repairOrderId,
        SignatureConvert.b64ToBlob(signatureDataUrlSplit[1], 'image/png'),
      );
      alert("L'ordre de réparation a bien été crée");

      window.location.reload();
    } catch (error) {
      alert("Erreur lors de la soumission de l'ordre de réparation");
    }
  };

  useEffect(() => {
    if (quote !== null) {
      setRepairOrder({
        ...quote,
        quoteIri: quote.quoteIri,
        repairsRequested: quote.items,
        uiMarkers: [],
        repairs: [],
        partsToOrder: false,
        estimatedDate: new Date(),
        note: '',
      });
      setSteps({
        ...steps,
        index: 1,
        list: {
          ...steps.list,
          0: {
            ...steps.list[steps.index],
            isValid: true,
          },
          1: {
            ...steps.list[steps.index + 1],
            isValid: true,
          },
        },
      });
    }
  }, []);

  const renderStep = () => {
    switch (steps.list[steps.index]) {
      case steps.list[0]:
        return (
          <QuoteStep
            repairOrder={repairOrder}
            setRepairOrder={setRepairOrder}
            steps={steps}
            setSteps={setSteps}
            showQuote={showQuote}
            showRepairOrder={showRepairOrder}
          />
        );
      case steps.list[1]:
        return (
          <CheckupStep
            repairOrder={repairOrder}
            setRepairOrder={setRepairOrder}
            steps={steps}
            setSteps={setSteps}
          />
        );
      case steps.list[2]:
        return (
          <RepairStep
            repairOrder={repairOrder}
            setRepairOrder={setRepairOrder}
            validateField={validateField}
            steps={steps}
            setSteps={setSteps}
          />
        );
      case steps.list[3]:
        return (
          <SignatureStep
            repairOrder={repairOrder}
            setRepairOrder={setRepairOrder}
            signatureRef={signatureRef}
            onSubmit={handleSubmit}
            validateField={validateField}
            steps={steps}
            setSteps={setSteps}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className='app'>
      <header className='app__header'>
        <a href='/'>
          <img src={Logo} alt='Fix Control' />
        </a>
        <Progressbar steps={steps} setSteps={setSteps} />
      </header>

      <div className='app__body'>{renderStep()}</div>
    </div>
  );
};

export default Create;
