import React, { useEffect, useState } from 'react';
import Textarea from 'components/Form/Textarea';
import SignaturePad from 'react-signature-pad-wrapper';
import Modal from 'components/Modal/Modal';
import Trash from 'assets/images/icons/trash.svg';

const SignatureStep = props => {
  const {
    onSubmit,
    repairOrder,
    setRepairOrder,
    signatureRef,
    setSteps,
    steps,
  } = props;

  const [showCgu, setShowCgu] = useState(false);
  const [isValidCgu, setIsValidCgu] = useState(false);
  const [isValidSignature, setIsValidSignature] = useState(true);

  const previousStep = () => {
    setSteps({
      ...steps,
      index: steps.index - 1,
    });
  };

  const handleNoteChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setRepairOrder({ ...repairOrder, [name]: value });
  };

  const handlePrevious = () => {
    setRepairOrder(repairOrder);
    previousStep();
  };

  useEffect(() => {
    const signatureInstance = signatureRef.current.instance;
    setIsValidSignature(!signatureInstance.isEmpty());

    signatureRef.current.instance.addEventListener('endStroke', () =>
      setIsValidSignature(!signatureInstance.isEmpty()),
    );
  }, [signatureRef]);

  return (
    <div className='signature-step'>
      <div className='signature-step__position'>
        étape {steps.index + 1}/{Object.values({ ...steps.list }).length}
      </div>

      <div className='signature-step__note'>
        <div className='signature-step__title'>Remarques ?</div>
        <Textarea name='note' onChange={handleNoteChange} />
      </div>

      <div className='signature-step__signature'>
        <div className='signature-step__title'>Signature client</div>

        <div className='signature-step__cgu'>
          <input
            type='checkbox'
            checked={isValidCgu}
            onClick={() => setIsValidCgu(!isValidCgu)}
          />
          <label>
            J&apos;accepte les{' '}
            <span onClick={() => setShowCgu(true)}>conditions générales</span>
          </label>

          <Modal
            title='Conditions générales'
            onClose={() => setShowCgu(false)}
            show={showCgu}
          >
            <iframe
              src={`${process.env.REACT_APP_DOMAIN_URL}/cgu`}
              frameBorder='0'
              title='CGU'
            />
          </Modal>
        </div>

        <div className='signature-step__signature-wrapper'>
          <SignaturePad
            ref={signatureRef}
            height={158}
            options={{ penColor: 'rgb(0, 0, 0)' }}
          />
        </div>
        <button
          type='button'
          onClick={() => {
            signatureRef.current.instance.clear();
            signatureRef.current.instance.addEventListener('endStroke', () =>
              setIsValidSignature(!signatureRef.current.instance.isEmpty()),
            );
            setIsValidSignature(false);
          }}
        >
          <img src={Trash} alt='Effacer la signature' />
        </button>
      </div>
      <div className='signature-step__buttons'>
        <button
          type='button'
          className='button button-secondary'
          onClick={handlePrevious}
        >
          Précédent
        </button>
        <button
          type='button'
          className='button button-primary'
          onClick={onSubmit}
          disabled={!isValidCgu || !isValidSignature}
        >
          Terminer et envoyer
        </button>
      </div>
    </div>
  );
};

export default SignatureStep;
