import React, { useEffect, useState } from 'react';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import CategoriesApi from 'services/api/CategoriesApi';
import ModelsApi from 'services/api/ModelsApi';
import VehiclesApi from 'services/api/VehiclesApi';
import SearchIcon from 'assets/images/icons/search.svg';

const Manage = props => {
  const { vehicle, setVehicle, setIsValidVehicle, validateField } = props;

  const [modelSearch, setModelSearch] = useState([]);
  const [models, setModels] = useState([]);

  const [vehicleLocal, setVehicleLocal] = useState(vehicle);

  const [searchMode, setSearchMode] = useState(false);

  const [canSave, setCanSave] = useState(false);

  const [search, setSearch] = useState({
    text: '',
    result: [],
  });

  const [categories, setCategories] = useState([]);

  const vehicleFields = {
    plate: {
      name: 'plate',
      label: 'Immatriculation',
      fieldType: 'input',
      attributType: 'text',
      value: vehicle.plate,
      validation: ['required'],
    },
    category: {
      name: 'category',
      label: 'Catégorie',
      fieldType: 'select',
      value: vehicle.category,
      validation: ['required'],
    },
    model: {
      name: 'modelSearch',
      label: 'Modèle',
      fieldType: 'input',
      attributType: 'text',
      list: 'models',
      value: modelSearch,
      validation: ['required'],
    },
  };

  const fetchCategories = async () => {
    const categoriesResult = await CategoriesApi.findAll();
    const categoriesFormatted = [];

    categoriesFormatted.push({
      label: 'Sélectionner une catégorie',
      value: '',
    });
    categoriesResult.map(category =>
      categoriesFormatted.push({
        label: category.name,
        value: category['@id'],
      }),
    );
    setCategories(categoriesFormatted);
  };

  const fetchModels = async searchData => {
    const modelsFromApi = await ModelsApi.search(searchData);

    setModels(modelsFromApi);
  };

  const handleVehicleLocalChange = ({ currentTarget }) => {
    const { name } = currentTarget;
    let { value } = currentTarget;

    if (name === 'modelSearch') {
      fetchModels(value);
      setModelSearch(value);
      setVehicleLocal({
        ...vehicleLocal,
        model: '',
        manufacturerName: '',
        modelName: '',
      });
    } else {
      if (name === 'plate') {
        if (!/^[a-zA-z0-9]*$/.test(value) && value !== '') {
          return;
        }
        value = value.toUpperCase();
      }

      setVehicleLocal({
        ...vehicleLocal,
        [name]: value,
      });
    }
  };

  const handleSearchChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;

    setSearch({ ...search, [name]: value });
  };

  const handleSearch = async () => {
    if (search.text === '') {
      return;
    }

    try {
      const vehiclesResult = await VehiclesApi.search(search.text);

      setSearch({
        ...search,
        result: vehiclesResult,
      });
    } catch (error) {
      setSearch({ ...search, result: null });
      alert('Aucun résultat');
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleVehicleSelected = vehicleSelected => {
    setVehicleLocal({
      '@id': vehicleSelected['@id'],
      id: vehicleSelected.id,
      plate: vehicleSelected.plate,
      category: vehicleSelected.category,
      manufacturerName: vehicleSelected.manufacturerName,
      modelName: vehicleSelected.modelName,
      model: vehicleSelected.model,
    });
    setSearchMode(false);
    setModelSearch(
      `${vehicleSelected.manufacturerName} ${vehicleSelected.modelName}`,
    );
  };

  const handleModelSelected = modelSelected => {
    setVehicleLocal({
      ...vehicleLocal,
      model: modelSelected['@id'],
      manufacturerName: modelSelected.manufacturerName,
      modelName: modelSelected.name,
    });

    setModelSearch(`${modelSelected.manufacturerName} ${modelSelected.name}`);
  };

  const renderFieldValue = name => {
    switch (name) {
      case 'plate':
        return vehicleLocal.plate;
      case 'category':
        return vehicleLocal.category;
      case 'modelSearch':
        return modelSearch;
      default:
        return null;
    }
  };

  const renderFieldSelectOptions = name => {
    switch (name) {
      case 'category':
        return categories;
      default:
        return null;
    }
  };

  useEffect(() => {
    fetchCategories();

    if (
      vehicleLocal.model !== '' ||
      vehicleLocal.model !== undefined ||
      vehicleLocal.model !== null
    ) {
      setModelSearch(
        `${vehicleLocal.manufacturerName} ${vehicleLocal.modelName}`,
      );
    }
  }, []);

  useEffect(() => {
    const isValid =
      Object.values(vehicleFields).filter(field => {
        return !validateField(renderFieldValue(field.name), field.validation);
      }).length === 0 && vehicleLocal.model !== '';
    setCanSave(isValid);
    setIsValidVehicle(isValid);
  }, [vehicleLocal]);

  return (
    <div className='quote-vehicle-manage'>
      {searchMode && (
        <div>
          <div className='search-step'>
            <div className='step step--search'>
              <div className='step__title'>
                <span>Trouver un véhicule</span>{' '}
                <img src={SearchIcon} alt='Icone loupe' />
                <button
                  type='button'
                  className='button button-primary'
                  onClick={() => {
                    setSearchMode(false);
                  }}
                >
                  Retour
                </button>
              </div>
              <p className='step__paragraph'>Immatriculation</p>
              <div className='search-step__form'>
                <Input
                  name='text'
                  value={search.text}
                  onChange={handleSearchChange}
                  onKeyDown={handleKeyDown}
                />
                <button
                  type='button'
                  className='button button-primary'
                  onClick={handleSearch}
                  disabled={search.text === ''}
                >
                  Chercher
                </button>
              </div>
              <div className='search-step__results'>
                {search.result !== [] &&
                  search.result.map(vehicleResult => (
                    <div
                      key={vehicleResult.id}
                      className='card card--search'
                      onClick={() => handleVehicleSelected(vehicleResult)}
                    >
                      <div>{`${vehicleResult.manufacturerName} ${vehicleResult.modelName}`}</div>
                      <div>{vehicleResult.repairOrderVehicleImageToShow}</div>
                      <div>{vehicleResult.plate}</div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {!searchMode && (
        <form className='quote-vehicle-manage__form'>
          {Object.values(vehicleFields).map(field => (
            <>
              {field.fieldType === 'input' && (
                <div className='quote-vehicle-manage__form-input'>
                  <Input
                    key={field.name}
                    name={field.name}
                    label={field.label}
                    value={renderFieldValue(field.name)}
                    type={field.attributType}
                    onChange={handleVehicleLocalChange}
                    onFocus={() => {
                      if (field.list) {
                        const inputList = document.getElementById(field.list);

                        if (inputList !== null) {
                          inputList.style.display = 'block';
                        }
                      }
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        if (field.list) {
                          const inputList = document.getElementById(field.list);
                          if (inputList !== null) {
                            inputList.style.display = 'none';
                          }
                        }
                      }, 250);
                    }}
                    required={Object.values(field.validation).includes(
                      'required',
                    )}
                  />
                  {field.list && (
                    <div id={field.list} className='input-list'>
                      {models &&
                        models !== undefined &&
                        models.map(model => (
                          <button
                            type='button'
                            onClick={() => handleModelSelected(model)}
                          >
                            {`${model.manufacturerName} ${model.name}`}
                          </button>
                        ))}
                    </div>
                  )}
                </div>
              )}
              {field.fieldType === 'select' && (
                <Select
                  key={field.name}
                  name={field.name}
                  label={field.label}
                  value={renderFieldValue(field.name)}
                  options={renderFieldSelectOptions(field.name)}
                  onChange={handleVehicleLocalChange}
                  required={Object.values(field.validation).includes(
                    'required',
                  )}
                />
              )}
            </>
          ))}
          <div>
            {vehicle.id === null && (
              <button
                type='button'
                className='button button-primary'
                onClick={() => {
                  setSearchMode(true);
                }}
              >
                Chercher un véhicule
              </button>
            )}
            <button
              type='button'
              className='button button-primary'
              onClick={() => {
                setVehicle(vehicleLocal);
              }}
              disabled={!canSave}
            >
              Enregistrer
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default Manage;
