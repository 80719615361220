import React, { useEffect, useState } from 'react';
import ImageMarker from 'react-image-marker';

// import VehiculeTypePrevious from 'assets/images/icons/vehicleTypePrevious.svg';
// import VehiculeTypeNext from 'assets/images/icons/vehicleTypeNext.svg';
import VehiculeViewPrevious from 'assets/images/icons/vehicleViewPrevious.svg';
import VehiculeViewNext from 'assets/images/icons/vehicleViewNext.svg';
import MarkerPoint from 'assets/images/icons/markerPoint.svg';
import Trash from 'assets/images/icons/trash.svg';

import CarFront from 'assets/images/vehicles-views/Car/front.png';
import CarLeft from 'assets/images/vehicles-views/Car/left.png';
import CarRear from 'assets/images/vehicles-views/Car/rear.png';
import CarRight from 'assets/images/vehicles-views/Car/right.png';
import CarTop from 'assets/images/vehicles-views/Car/top.png';

import SmallUtilityFront from 'assets/images/vehicles-views/SmallUtility/front.png';
import SmallUtilityLeft from 'assets/images/vehicles-views/SmallUtility/left.png';
import SmallUtilityRear from 'assets/images/vehicles-views/SmallUtility/rear.png';
import SmallUtilityRight from 'assets/images/vehicles-views/SmallUtility/right.png';
import SmallUtilityTop from 'assets/images/vehicles-views/SmallUtility/top.png';

import LargeUtilityFront from 'assets/images/vehicles-views/LargeUtility/front.png';
import LargeUtilityLeft from 'assets/images/vehicles-views/LargeUtility/left.png';
import LargeUtilityRear from 'assets/images/vehicles-views/LargeUtility/rear.png';
import LargeUtilityRight from 'assets/images/vehicles-views/LargeUtility/right.png';
import LargeUtilityTop from 'assets/images/vehicles-views/LargeUtility/top.png';

import SportFront from 'assets/images/vehicles-views/Sport/front.png';
import SportLeft from 'assets/images/vehicles-views/Sport/left.png';
import SportRear from 'assets/images/vehicles-views/Sport/rear.png';
import SportRight from 'assets/images/vehicles-views/Sport/right.png';
import SportTop from 'assets/images/vehicles-views/Sport/top.png';

import TruckFront from 'assets/images/vehicles-views/Truck/front.png';
import TruckLeft from 'assets/images/vehicles-views/Truck/left.png';
import TruckRear from 'assets/images/vehicles-views/Truck/rear.png';
import TruckRight from 'assets/images/vehicles-views/Truck/right.png';
import TruckTop from 'assets/images/vehicles-views/Truck/top.png';

import MotorbikeFront from 'assets/images/vehicles-views/Motorbike/front.png';
import MotorbikeLeft from 'assets/images/vehicles-views/Motorbike/left.png';
import MotorbikeRear from 'assets/images/vehicles-views/Motorbike/rear.png';
import MotorbikeRight from 'assets/images/vehicles-views/Motorbike/right.png';
import MotorbikeTop from 'assets/images/vehicles-views/Motorbike/top.png';

const CheckupStep = props => {
  const { repairOrder, setRepairOrder, setSteps, steps } = props;

  const carViews = [
    {
      name: 'front',
      view: CarFront,
    },
    {
      name: 'left',
      view: CarLeft,
    },
    {
      name: 'rear',
      view: CarRear,
    },
    {
      name: 'right',
      view: CarRight,
    },
    {
      name: 'top',
      view: CarTop,
    },
  ];
  const smallUtilityViews = [
    {
      name: 'front',
      view: SmallUtilityFront,
    },
    {
      name: 'left',
      view: SmallUtilityLeft,
    },
    {
      name: 'rear',
      view: SmallUtilityRear,
    },
    {
      name: 'right',
      view: SmallUtilityRight,
    },
    {
      name: 'top',
      view: SmallUtilityTop,
    },
  ];
  const largeUtilityViews = [
    {
      name: 'front',
      view: LargeUtilityFront,
    },
    {
      name: 'left',
      view: LargeUtilityLeft,
    },
    {
      name: 'rear',
      view: LargeUtilityRear,
    },
    {
      name: 'right',
      view: LargeUtilityRight,
    },
    {
      name: 'top',
      view: LargeUtilityTop,
    },
  ];
  const sportViews = [
    {
      name: 'front',
      view: SportFront,
    },
    {
      name: 'left',
      view: SportLeft,
    },
    {
      name: 'rear',
      view: SportRear,
    },
    {
      name: 'right',
      view: SportRight,
    },
    {
      name: 'top',
      view: SportTop,
    },
  ];
  const truckViews = [
    {
      name: 'front',
      view: TruckFront,
    },
    {
      name: 'left',
      view: TruckLeft,
    },
    {
      name: 'rear',
      view: TruckRear,
    },
    {
      name: 'right',
      view: TruckRight,
    },
    {
      name: 'top',
      view: TruckTop,
    },
  ];
  const motorbikeViews = [
    {
      name: 'front',
      view: MotorbikeFront,
    },
    {
      name: 'left',
      view: MotorbikeLeft,
    },
    {
      name: 'rear',
      view: MotorbikeRear,
    },
    {
      name: 'right',
      view: MotorbikeRight,
    },
    {
      name: 'top',
      view: MotorbikeTop,
    },
  ];

  const [checkup, setCheckup] = useState({
    vehicleTypeCurrentIndex: 0,
    vehicleTypes: [
      {
        name: 'Voiture',
        views: carViews,
        viewCurrentIndex: 0,
      },
      {
        name: 'Petit utilitaire',
        views: smallUtilityViews,
        viewCurrentIndex: 0,
      },
      {
        name: 'Grand utilitaire',
        views: largeUtilityViews,
        viewCurrentIndex: 0,
      },
      {
        name: 'Sportive',
        views: sportViews,
        viewCurrentIndex: 0,
      },
      {
        name: 'Camion',
        views: truckViews,
        viewCurrentIndex: 0,
      },
      {
        name: '2-roues',
        views: motorbikeViews,
        viewCurrentIndex: 0,
      },
    ],
  });

  const [markers, setMarkers] = useState({
    type: checkup.vehicleTypes[checkup.vehicleTypeCurrentIndex].name,
  });

  const nextStep = () => {
    setSteps({
      index: steps.index + 1,
      list: {
        ...steps.list,
        [steps.index]: {
          ...steps.list[steps.index],
          isValid: true,
        },
        [steps.index + 1]: {
          ...steps.list[steps.index + 1],
          isValid: true,
        },
      },
    });
  };

  const previousStep = () => {
    setSteps({
      ...steps,
      index: steps.index - 1,
    });
  };

  const setMarkersWrapper = marker => {
    const currentViewIndex =
      checkup.vehicleTypes[checkup.vehicleTypeCurrentIndex].viewCurrentIndex;
    const currentViewName =
      checkup.vehicleTypes[checkup.vehicleTypeCurrentIndex].views[
        currentViewIndex
      ].name;
    let markersCopy = { ...markers };

    if (markersCopy[currentViewName]) {
      markersCopy[currentViewName].push(marker);
    } else {
      markersCopy = { ...markersCopy, [currentViewName]: [marker] };
    }

    setMarkers({
      ...markersCopy,
      type: checkup.vehicleTypes[checkup.vehicleTypeCurrentIndex].name,
    });
  };

  const handleRemoveViewMarkers = () => {
    setMarkers({
      ...markers,
      [checkup.vehicleTypes[checkup.vehicleTypeCurrentIndex].views[
        checkup.vehicleTypes[checkup.vehicleTypeCurrentIndex].viewCurrentIndex
      ].name]: [],
    });
  };

  // const handleChangeVehicleType = i => {
  //   let newIndex = 0;
  //   if (checkup.vehicleTypeCurrentIndex === 0 && i === -1) {
  //     newIndex = Object.values({ ...checkup.vehicleTypes }).length - 1;
  //   } else if (
  //     Object.values({ ...checkup.vehicleTypes }).length - 1 ===
  //       checkup.vehicleTypeCurrentIndex &&
  //     i === 1
  //   ) {
  //     newIndex = 0;
  //   } else {
  //     newIndex = checkup.vehicleTypeCurrentIndex + i;
  //   }

  //   setCheckup({
  //     ...checkup,
  //     vehicleTypeCurrentIndex: newIndex,
  //     vehicleTypes: {
  //       ...checkup.vehicleTypes,
  //       [checkup.vehicleTypeCurrentIndex]: {
  //         ...checkup.vehicleTypes[checkup.vehicleTypeCurrentIndex],
  //         viewCurrentIndex: 0,
  //       },
  //     },
  //   });
  //   setMarkers([]);
  // };

  const handleChangeVehicleView = i => {
    let newIndex = 0;

    const vehicleType = checkup.vehicleTypes[checkup.vehicleTypeCurrentIndex];

    if (vehicleType.viewCurrentIndex === 0 && i === -1) {
      newIndex = Object.values({ ...vehicleType.views }).length - 1;
    } else if (
      Object.values({ ...vehicleType.views }).length - 1 ===
        vehicleType.viewCurrentIndex &&
      i === 1
    ) {
      newIndex = 0;
    } else {
      newIndex = vehicleType.viewCurrentIndex + i;
    }

    setCheckup({
      ...checkup,
      vehicleTypes: {
        ...checkup.vehicleTypes,
        [checkup.vehicleTypeCurrentIndex]: {
          ...checkup.vehicleTypes[checkup.vehicleTypeCurrentIndex],
          viewCurrentIndex: newIndex,
        },
      },
    });
  };

  const handlePrevious = () => {
    setRepairOrder(repairOrder);
    previousStep();
  };

  const handleNext = () => {
    setRepairOrder(repairOrder);
    nextStep();
  };

  useEffect(() => {
    setRepairOrder({ ...repairOrder, uiMarkers: markers });
  }, [markers]);

  useEffect(() => {
    setMarkers(repairOrder.uiMarkers);
  }, []);

  useEffect(() => {
    let vehicleTypeIndex = 0;

    switch (repairOrder.repairOrderVehicleImageToShow) {
      case 'Voiture':
        vehicleTypeIndex = 0;
        break;
      case 'Petit utilitaire':
        vehicleTypeIndex = 1;
        break;
      case 'Grand-utilitaire':
        vehicleTypeIndex = 2;
        break;
      case 'Sportive':
        vehicleTypeIndex = 3;
        break;
      case 'Camion':
        vehicleTypeIndex = 4;
        break;
      case '2-roues':
        vehicleTypeIndex = 5;
        break;
      default:
        vehicleTypeIndex = 0;
        break;
    }

    setCheckup({ ...checkup, vehicleTypeCurrentIndex: vehicleTypeIndex });
  }, [repairOrder]);

  return (
    <div className='checkup-step'>
      <div className='checkup-step__position'>
        étape {steps.index + 1}/{Object.values({ ...steps.list }).length}
      </div>

      <div className='checkup-step__subtitle'>Affichage</div>

      <div className='checkup-step__title'>État des lieux du véhicule</div>

      <div className='checkup-step__vehicle-type'>
        {/* <button type='button' onClick={() => handleChangeVehicleType(-1)}>
          <img src={VehiculeTypePrevious} alt='Type de véhicule précédent' />
        </button> */}
        <span>
          {checkup.vehicleTypes[checkup.vehicleTypeCurrentIndex].name}
        </span>
        {/* <button type='button' onClick={() => handleChangeVehicleType(1)}>
          <img src={VehiculeTypeNext} alt='Type de véhicule suivant' />
        </button> */}
      </div>

      <div className='checkup-step__vehicle-view-previous'>
        <button type='button' onClick={() => handleChangeVehicleView(-1)}>
          <img src={VehiculeViewPrevious} alt='Vue du véhicule précédent' />
        </button>
      </div>

      <div className='checkup-step__vehicle-view'>
        <ImageMarker
          src={
            checkup.vehicleTypes[checkup.vehicleTypeCurrentIndex].views[
              checkup.vehicleTypes[checkup.vehicleTypeCurrentIndex]
                .viewCurrentIndex
            ].view
          }
          markers={
            markers[
              checkup.vehicleTypes[checkup.vehicleTypeCurrentIndex].views[
                checkup.vehicleTypes[checkup.vehicleTypeCurrentIndex]
                  .viewCurrentIndex
              ].name
            ] ?? []
          }
          onAddMarker={marker => setMarkersWrapper(marker)}
          markerComponent={() => (
            <img src={MarkerPoint} alt='Marqueur' className='marker-point' />
          )}
          alt='Vue'
        />
      </div>

      <div className='checkup-step__vehicle-view-next'>
        <button type='button' onClick={() => handleChangeVehicleView(1)}>
          <img src={VehiculeViewNext} alt='Vue du véhicule suivant' />
        </button>
      </div>

      <div className='checkup-step__buttons'>
        <button
          type='button'
          className='button button-secondary'
          onClick={handlePrevious}
        >
          Précédent
        </button>
        <button
          type='button'
          className='button button-primary'
          onClick={handleNext}
        >
          Suivant
        </button>
      </div>

      <div className='checkup-step__trash'>
        <button type='button' onClick={handleRemoveViewMarkers}>
          <img src={Trash} alt='Supprimer les marques' />
        </button>
      </div>
    </div>
  );
};

export default CheckupStep;
