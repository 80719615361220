/* eslint-disable no-alert */
import React, { useState } from 'react';
import CreateRepairOrder from 'pages/RepairOrder/Create';
import ListRepairOrder from 'pages/RepairOrder/List';
import CreateQuote from 'pages/Quote/Create';
import Logo from 'assets/images/logo.svg';

const Show = () => {
  const [quote, setQuote] = useState(null);
  const [repairOrderId, setRepairOrderId] = useState(null);
  const [view, setView] = useState(null);
  const [views] = useState([
    {
      name: 'DEVIS',
    },
    {
      name: 'CREER UN OR',
    },
    {
      name: 'VOIR LES ORs',
    },
  ]);

  const convertQuoteAcceptedToRepairOrder = quoteToConvert => {
    setQuote(quoteToConvert);
    setView('CREER UN OR');
  };

  const showQuote = quoteToShow => {
    setQuote(quoteToShow);
    setView('DEVIS');
  };

  const showRepairOrder = repairOrders => {
    const repairOrderSplit = Object.values(repairOrders)[0].split('/');
    setRepairOrderId(repairOrderSplit[repairOrderSplit.length - 1]);
    setView('VOIR LES ORs');
  };

  const renderView = () => {
    switch (view) {
      case views[0].name:
        return (
          <CreateQuote
            quote={quote}
            convertQuoteAcceptedToRepairOrder={
              convertQuoteAcceptedToRepairOrder
            }
            showRepairOrder={showRepairOrder}
          />
        );
      case views[1].name:
        return (
          <CreateRepairOrder
            quote={quote}
            showQuote={showQuote}
            showRepairOrder={showRepairOrder}
          />
        );
      case views[2].name:
        return <ListRepairOrder repairOrderId={repairOrderId} />;
      default:
        return null;
    }
  };

  return (
    <div className='app'>
      {view !== null && renderView()}
      {view === null && (
        <>
          <header className='app__header'>
            <a href='/'>
              <img src={Logo} alt='Fix Control' />
            </a>
          </header>

          <div className='app__body app__body--home'>
            {views.map(viewLocal => (
              <div
                key={viewLocal.name}
                className='card'
                onClick={() => {
                  setView(viewLocal.name);
                }}
              >
                <div>{viewLocal.name}</div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Show;
